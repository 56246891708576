import { inject, Pipe, PipeTransform } from '@angular/core';
import { IMenu } from '@shared/components/menu/menu.models';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { IAvailablePathUserType, TPageType } from '@shared/models//route-management.model';

@Pipe({
    standalone: true,
    name: 'menuActive',
})
export class MenuActivePipe implements PipeTransform {
    private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);

    transform(item: IMenu, url: string): boolean {
        if (url == '/') return item.url == 'users';

        let itemUrl: string = '/' + item.url;
        const pageType: TPageType = url.split('/')[1] as TPageType;

        if (pageType) {
            const page = this.routeManagement.routeAccess[pageType];

            if (page) {
                const availablePaths: IAvailablePathUserType[] =
                    page.pathConfig.availablePaths || [];

                if (availablePaths.length) {
                    for (const availablePath of availablePaths) {
                        if (itemUrl + '/' + availablePath.path === url) return true;
                    }
                } else {
                    if (itemUrl == '/') itemUrl = this.routeManagement.getPath(pageType);
                }
            }
        }

        const words: string[] = url.split('/').filter(w => !!w);

        return this.isItemInUrl(words, itemUrl);
    }

    isItemInUrl(words: string[], itemUrl: string): boolean {
        for (const word of words) {
            const regex = new RegExp(`\\b${word}\\b`, 'i');
            if (regex.test(itemUrl)) return true;
        }
        return false;
    }
}
