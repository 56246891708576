import { Directive, effect, ElementRef, inject, Renderer2 } from '@angular/core';
import { ThemeService, TTheme } from '@shared/services/theme.service';

@Directive({
    standalone: true,
    selector: '[theme-class]',
})
export class ThemeClassDirective {
    private themeService: ThemeService = inject(ThemeService);
    private hostElement: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);

    constructor() {
        effect(() => {
            this.removeClass('light-theme');
            this.removeClass('dark-theme');

            switch (this.themeService.theme()) {
                case 'light-theme':
                    this.addClass('light-theme');
                    break;
                case 'dark-theme':
                    this.addClass('dark-theme');
                    break;
            }
        });
    }

    addClass(className: TTheme): void {
        this.renderer.addClass(this.hostElement.nativeElement, className);
    }

    removeClass(className: TTheme): void {
        this.renderer.removeClass(this.hostElement.nativeElement, className);
    }
}
