import { FormArray, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { FormHelper } from '@utils/form-helper/form-helper';
import { FormHelperGroup } from '@utils/form-helper/form-helper-group';
import { BehaviorSubject, Observable } from 'rxjs';

export class FormHelperArray<TData> {
    private readonly formArray$: BehaviorSubject<FormArray> = new BehaviorSubject<FormArray>(
        new FormArray([new FormControl({})]),
    );
    private readonly formHelper: FormHelper<TData> = new FormHelper<TData>();
    private formBuilder: FormBuilder = new FormBuilder();

    constructor(value?: TData[]) {
        if (value) this.build(value);
    }

    get form(): FormArray {
        return this.formArray$.getValue() as FormArray;
    }

    set form(value: FormArray) {
        this.formArray$.next(value);
    }

    get controls() {
        return this.form.controls;
    }

    get fromGroups(): FormHelperGroup<TData>[] {
        return this.form.controls.map((control, index) => {
            return this.getFormGroup(index);
        });
    }

    get disabled() {
        return this.form.disabled;
    }

    build(value: TData[]): void {
        this.form = this.formBuilder.array([]);
        if (value.length) {
            // reverse to keep original order
            const formArray = this.formHelper
                .getForm(value, undefined, { isArray: true })
                .reverse();
            this.form.controls = this.controls.concat(formArray);
        }
    }

    getRawValue(): TData[] {
        return this.form.getRawValue();
    }

    changes(): Observable<TData[]> {
        return this.form.valueChanges;
    }

    enable(): void {
        this.form.enable();
    }

    disable(): void {
        this.form.disable();
    }

    getValue<K extends keyof TData>(index: number, target: K): TData[K] {
        return this.getControl(index, target)?.value;
    }

    addControl<K extends keyof TData>(data: TData, index?: number) {
        const nestedGroupForm: FormHelperGroup<TData> = new FormHelperGroup<TData>();
        nestedGroupForm.build(data);

        const keys = Object.keys(this.formHelper.nestedFormGroup)
            .map(Number)
            .sort((a, b) => a - b);
        const targetIndex = index ?? keys.length;

        // Shift existing keys to higher values if necessary
        for (let i = keys.length; i > targetIndex; i--) {
            if (keys.includes(i)) {
                this.formHelper.nestedFormGroup[(i + 1) as K] =
                    this.formHelper.nestedFormGroup[i as K];
            }
        }

        // Add new group form at the specified index
        this.formHelper.nestedFormGroup[targetIndex as K] = nestedGroupForm;

        // Ensure to insert at the correct position in the form array
        this.form.insert(targetIndex, nestedGroupForm.form);
    }

    removeControl<K extends keyof TData>(index: number): void {
        const keys = Object.keys(this.formHelper.nestedFormGroup);
        const keyToRemove: K = keys[index] as K;

        delete this.formHelper.nestedFormGroup[keyToRemove];
        this.controls.splice(index, 1);

        // Shift the keys after removing the entry
        for (let i = index + 1; i < keys.length; i++) {
            const previousKey: K = keys[i - 1] as K;
            const currentKey: K = keys[i] as K;
            this.formHelper.nestedFormGroup[previousKey] =
                this.formHelper.nestedFormGroup[currentKey];
        }

        // Clean up nested form by removing undefined entries
        Object.keys(this.formHelper.nestedFormGroup).forEach(key => {
            const prop = key as K;
            if (!this.formHelper.nestedFormGroup[prop])
                delete this.formHelper.nestedFormGroup[prop];
        });
    }

    setMandatoryFields(fields: (keyof TData)[]): void {
        this.controls.forEach((control, index) => {
            this.getFormGroup(index).setMandatoryFields(fields);
        });
    }

    setValidators<K extends keyof TData>(
        fields: K[],
        validators: ValidatorFn | ValidatorFn[],
    ): void {
        this.controls.forEach((control, index) => {
            this.getFormGroup(index).setValidators(fields, Validators.required);
        });
    }

    clearValidators(...fields: (keyof TData)[]): void {
        const keys: keyof TData = fields.join(',') as keyof TData;
        this.controls.forEach((control, index) => {
            this.getFormGroup(index).clearValidators(keys);
        });
    }

    enableFields(...fields: (keyof TData)[]): void {
        const keys: keyof TData = fields.join(',') as keyof TData;
        this.controls.forEach((control, index) => {
            this.getFormGroup(index).enableFields(keys);
        });
    }

    disableFields(...fields: (keyof TData)[]): void {
        const keys: keyof TData = fields.join(',') as keyof TData;
        this.controls.forEach((control, index) => {
            this.getFormGroup(index).disableFields(keys);
        });
    }

    getControl<K extends keyof TData>(index: number, target: K) {
        return this.getFormGroup(index).getControl(target);
    }

    getFormGroup<K extends keyof TData>(index: number): FormHelperGroup<TData> {
        const key: K = index as K;
        return this.formHelper.nestedFormGroup[key];
    }

    invalid(): boolean {
        return this.form.invalid;
    }

    validateForm(): void {
        this.form.markAllAsTouched();

        this.controls.forEach((control, index) => {
            this.getFormGroup(index).validateForm();
        });

        this.form.updateValueAndValidity();
    }
}
