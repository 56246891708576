import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SitesHttpService } from '@core/http/sites-http.service';
import { SitesService } from '@shared/services/sites.service';
import { IAuditStatus, IAuditStatusDto } from '@modules/site-details/site-details.models';
import { AppTools } from '@shared/services/app-tools.service';

interface ISyncPage {
    pageIndex: number;
    pageSize: number;
    length: number;
}

@Injectable()
export class SiteAcsSyncService {
    private sitesHttp: SitesHttpService = inject(SitesHttpService);
    private sitesService: SitesService = inject(SitesService);

    readonly pollCount: number = 10;
    readonly pollInterval: number = 1000; //sec

    private readonly syncing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly syncingLogs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly syncingTraceLogs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false,
    );
    private readonly loadingLogs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly loadingTraceLogs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false,
    );
    private readonly auditStatus$: BehaviorSubject<IAuditStatus[]> = new BehaviorSubject<
        IAuditStatus[]
    >([]);
    private readonly auditStatusTrace$: BehaviorSubject<IAuditStatus[]> = new BehaviorSubject<
        IAuditStatus[]
    >([]);
    private readonly logsPage$: BehaviorSubject<ISyncPage> = new BehaviorSubject<ISyncPage>({
        pageIndex: 0,
        pageSize: 20,
        length: 20,
    });
    private readonly traceLogsPage$: BehaviorSubject<ISyncPage> = new BehaviorSubject<ISyncPage>({
        pageIndex: 0,
        pageSize: 20,
        length: 20,
    });

    readonly syncingObservable$: Observable<boolean> = this.syncing$.asObservable();
    readonly syncingLogsObservable$: Observable<boolean> = this.syncingLogs$.asObservable();
    readonly syncingTraceLogsObservable$: Observable<boolean> =
        this.syncingTraceLogs$.asObservable();
    readonly loadingLogsObservable$: Observable<boolean> = this.loadingLogs$.asObservable();
    readonly loadingTraceLogsObservable$: Observable<boolean> =
        this.loadingTraceLogs$.asObservable();
    readonly auditStatusObservable$: Observable<IAuditStatus[]> = this.auditStatus$.asObservable();
    readonly auditStatusTraceObservable$: Observable<IAuditStatus[]> =
        this.auditStatusTrace$.asObservable();
    readonly logsPageObservable$: Observable<ISyncPage> = this.logsPage$.asObservable();
    readonly traceLogsPageObservable$: Observable<ISyncPage> = this.traceLogsPage$.asObservable();

    set syncing(value: boolean) {
        this.syncing$.next(value);
    }

    set syncingLogs(value: boolean) {
        this.syncingLogs$.next(value);
    }

    set syncingTraceLogs(value: boolean) {
        this.syncingTraceLogs$.next(value);
    }

    set loadingLogs(value: boolean) {
        this.loadingLogs$.next(value);
    }

    set loadingTraceLogs(value: boolean) {
        this.loadingTraceLogs$.next(value);
    }

    set auditStatus(value: IAuditStatus[]) {
        this.auditStatus$.next(value);
    }

    set auditStatusTrace(value: IAuditStatus[]) {
        this.auditStatusTrace$.next(value);
    }

    set logsPage(value: Partial<ISyncPage>) {
        this.logsPage$.next({
            ...this.logsPage,
            ...value,
        });
    }

    set traceLogsPage(value: Partial<ISyncPage>) {
        this.traceLogsPage$.next({
            ...this.traceLogsPage,
            ...value,
        });
    }

    get syncing(): boolean {
        return this.syncing$.getValue();
    }

    get syncingLogs(): boolean {
        return this.syncingLogs$.getValue();
    }

    get syncingTraceLogs(): boolean {
        return this.syncingTraceLogs$.getValue();
    }

    get loadingLogs(): boolean {
        return this.loadingLogs$.getValue();
    }

    get loadingTraceLogs(): boolean {
        return this.loadingTraceLogs$.getValue();
    }

    get auditStatus(): IAuditStatus[] {
        return this.auditStatus$.getValue();
    }

    get auditStatusTrace(): IAuditStatus[] {
        return this.auditStatusTrace$.getValue();
    }

    get logsPage(): ISyncPage {
        return this.logsPage$.getValue();
    }

    get traceLogsPage(): ISyncPage {
        return this.traceLogsPage$.getValue();
    }

    async startSync(): Promise<string | undefined> {
        this.syncing = true;
        this.syncingLogs = true;
        this.syncingTraceLogs = true;

        const sync = await lastValueFrom(
            this.sitesHttp.syncSFS(this.sitesService.selectedSite._id),
        );
        this.syncing = false;

        if (typeof sync != 'string' && sync.message !== 'ok') {
            return sync.message;
        }

        if (!sync || typeof sync == 'string' || sync.message !== 'ok') {
            this.syncingLogs = false;
            this.syncingTraceLogs = false;
            return 'acm_sync_not_started_error';
        }

        this.startPollingLogs().then();
        this.startPollingTraceLogs().then();
        return undefined;
    }

    async startPollingLogs(count: number = 0): Promise<void> {
        await AppTools.timeout(this.pollInterval);

        if (count >= this.pollCount || !this.auditStatus.length || !this.syncingLogs) {
            this.syncingLogs = false;
            return;
        }

        this.setAudiStatus(this.logsPage.pageIndex, true).then();

        await this.startPollingLogs(count + 1);
    }

    async startPollingTraceLogs(count: number = 0): Promise<void> {
        await AppTools.timeout(this.pollInterval);

        if (count >= this.pollCount || !this.auditStatusTrace.length || !this.syncingTraceLogs) {
            this.syncingTraceLogs = false;
            return;
        }

        this.setAudiStatusTrace(this.traceLogsPage.pageIndex, true).then();

        await this.startPollingTraceLogs(count + 1);
    }

    async setAudiStatus(pageIndex: number = 0, skipLoading: boolean = false): Promise<void> {
        if (!skipLoading) this.loadingLogs = true;

        this.logsPage = {
            pageIndex: pageIndex,
        };

        const bookmark: number = this.logsPage.pageIndex * this.logsPage.pageSize;
        const auditStatus: IAuditStatusDto | null = await lastValueFrom(
            this.sitesHttp.getSFSAuditStatus(this.sitesService.selectedSite._id, bookmark),
        );

        this.auditStatus = auditStatus?.rows || [];
        this.logsPage = {
            length: this.auditStatus.length >= 20 ? bookmark + 40 : 0,
        };

        // stop sync: logic extracted from old cft tool -> check why this works
        let firstCycle: boolean = false;
        this.auditStatus.forEach(log => {
            if (log.kind === 'fullsync' && !firstCycle) {
                firstCycle = true;
                if (log.eventName === 'end') this.syncingLogs = false; // stop sync
            }
        });

        this.loadingLogs = false;
    }

    async setAudiStatusTrace(pageIndex: number = 0, skipLoading: boolean = false): Promise<void> {
        if (!skipLoading) this.loadingTraceLogs = true;

        this.traceLogsPage = {
            pageIndex: pageIndex,
        };

        const bookmark: number = this.traceLogsPage.pageIndex * this.traceLogsPage.pageSize;
        const auditStatusTrace: IAuditStatusDto | null = await lastValueFrom(
            this.sitesHttp.getSFSAuditStatus(this.sitesService.selectedSite._id, bookmark, 'trace'),
        );

        this.auditStatusTrace = auditStatusTrace?.rows || [];
        this.traceLogsPage = {
            length: this.auditStatusTrace.length >= 20 ? bookmark + 40 : 0,
        };

        // stop sync: logic extracted from old cft tool -> check why this works
        let firstCycle: boolean = false;
        this.auditStatusTrace.forEach(log => {
            if (log.kind === 'fullsync' && !firstCycle) {
                firstCycle = true;
                if (log.eventName === 'end') this.syncingTraceLogs = false; // stop sync
            }
        });

        this.loadingTraceLogs = false;
    }
}
