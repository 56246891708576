import {
    ComponentRef,
    Directive,
    ElementRef,
    inject,
    Injector,
    Input,
    OnDestroy,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { VisibleAppHighlightComponent } from '@shared/components/visible-app-highlight';

@Directive({
    standalone: true,
    selector: '[visible-on-app]',
})
export class VisibleOnAppDirective implements OnDestroy {
    private componentRef: ComponentRef<VisibleAppHighlightComponent>;
    private renderer: Renderer2 = inject(Renderer2);
    private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
    private injector: Injector = inject(Injector);
    private hostElement: ElementRef = inject(ElementRef);

    @Input('*ng-if-visible') set visible(visible: boolean) {
        if (!visible) this.destroyComponent();
    }

    @Input('replace-visible-text') set _text(text: string) {
        this.text = text;
        this.addText();
    }

    text: string = '';

    constructor() {
        this.createComponent();
    }

    private createComponent(): void {
        this.componentRef = this.viewContainerRef.createComponent(VisibleAppHighlightComponent, {
            injector: this.injector,
        });
        this.addText();
    }

    private addText(): void {
        if (this.componentRef && this.text) {
            this.componentRef.instance.text = this.text;
        }
    }

    private destroyComponent(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    ngOnDestroy(): void {
        this.destroyComponent();
    }
}
