import { ITimeZone, ITimeZoneDto } from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class TimeZoneModel extends GenericModel<ITimeZone, ITimeZoneDto> implements ITimeZone {
    _id: string;
    description: string;
    enabled: boolean;
    key: string;
    type: string;

    constructor(timeZone?: ITimeZoneDto) {
        super(timeZone, TimeZoneModel);

        this.description = timeZone?.description || '';
        this.key = timeZone?.key || '';
        this.enabled = timeZone?.enabled || false;

        if (timeZone?._id) this._id = timeZone._id;
        if (timeZone?.type) this.type = timeZone.type;
    }

    dataTransferObject(): ITimeZoneDto {
        const timeZoneDto: ITimeZoneDto = this.getOriginalData();

        timeZoneDto.description = this.description;
        timeZoneDto.enabled = this.enabled;

        if (this.type) timeZoneDto.type = this.type;

        return timeZoneDto;
    }
}
