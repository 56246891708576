import { GenericModel } from '@shared/models/generic.model';

export class GenericBuildingModel<T, TDto = T> extends GenericModel<T, TDto> {
    buildingId: string;
    groupId?: string;
    isAcs: boolean;
    sfsId: string;

    constructor(
        data: TDto | null,
        dataModel: new (dataDto: TDto | null) => T,
        buildingId?: string,
        isAcs?: boolean,
        sfsId?: string,
        groupId?: string,
    ) {
        super(data, dataModel);

        this.buildingId = buildingId || '';
        this.isAcs = isAcs || false;

        if (sfsId) this.sfsId = sfsId;
        if (groupId) this.groupId = groupId;
    }

    updateBuildingData(
        buildingId: string,
        isAcs?: boolean,
        sfsId?: string,
        groupId?: string,
    ): void {
        this.buildingId = buildingId;
        this.isAcs = isAcs || false;

        if (sfsId) this.sfsId = sfsId;
        if (groupId) this.groupId = groupId;
    }

    getFullBuildingId(): string {
        if (this.sfsId) return this.sfsId;
        return this.isAcs ? this.buildingId + ':' + this.groupId : this.buildingId;
    }
}
