import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDialogComponent } from '@shared/components/custom-dialog';
import { CustomDialogContentDirective } from '@shared/components/custom-dialog/directives';
import { IconComponent } from '@shared/components/icon/icon.component';

export interface IInformationDialogData {
    title?: string;
    texts: string[];
}

@Component({
    standalone: true,
    selector: 'information-dialog',
    template: `
        <custom-dialog [label]="title" hideActions>
            <ng-template customDialogContent>
                @for (text of data.texts; track text) {
                    <p>{{ text | translate }}</p>
                }
            </ng-template>
        </custom-dialog>
    `,
    styles: [
        `
            p {
                white-space: break-spaces;
            }

            p:first-of-type {
                margin-top: 0;
            }

            p:last-of-type {
                margin-bottom: 0;
            }
        `,
    ],
    imports: [
        TranslateModule,
        IconComponent,
        MatDialogModule,
        CustomDialogComponent,
        CustomDialogContentDirective,
    ],
})
export class InformationDialogComponent implements OnInit {
    title: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: IInformationDialogData) {}

    ngOnInit(): void {
        this.title = this.data.title || 'acm_information';
    }
}
