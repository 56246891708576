import { trigger, transition, animate, style, state, query, group } from '@angular/animations';

export const slider = trigger('routeAnimations', [
    transition('UserPage => UserDetails', slideTo('right')),
    transition('UserDetails => UserPage', slideTo('left')),
    transition('AccessGroup => AccessGroupDetails', slideTo('right')),
    transition('AccessGroupDetails => AccessGroup', slideTo('left')),
    transition('UsersManagement => UserOverviewDetails', slideTo('right')),
    transition('UserOverviewDetails => UsersManagement', slideTo('left')),
    transition('* => isLeft', slideTo('left')),
    transition('* => isRight', slideTo('right')),
    transition('isRight => *', slideTo('left')),
    transition('isLeft => *', slideTo('right')),
]);

function slideTo(direction: 'right' | 'left') {
    const optional = { optional: true };
    return [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    [direction]: 0,
                    width: '100%',
                }),
            ],
            optional,
        ),
        query(':enter', [style({ [direction]: '-100%' })], optional),
        group([
            query(
                ':leave',
                [animate('250ms ease-out', style({ [direction]: '100%', opacity: 0.01 }))],
                optional,
            ),
            query(':enter', [animate('250ms ease-out', style({ [direction]: '0%' }))], optional),
        ]),
        // Normalize the page style... Might not be necessary

        // Required only if you have child animations on the page
        // query(':leave', animateChild()),
        // query(':enter', animateChild()),
    ];
}

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('225ms', style({ opacity: 1 }))]),
]);

export const slideItemAnimation = trigger('slideItemAnimation', [
    transition(':enter', [
        style({ opacity: 0, height: '0' }),
        animate('100ms ease-out', style({ opacity: 1, height: '*' })),
    ]),
    transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('100ms ease-out', style({ opacity: 0, height: '0' })),
    ]),
]);

export const itemExpanded = trigger('itemExpanded', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
