import { Component, inject, OnInit, Signal } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { CustomCardComponent } from '@shared/components/custom-card';
import { TranslateModule } from '@ngx-translate/core';
import { BgColorVariablePipe } from '@shared/pipes';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IStyleVariable } from '@shared/interfaces';
import { AppStylesService } from '@shared/services/app-styles.service';

// https://github.com/scttcper/ngx-toastr/blob/master/src/app/pink.toast.ts

@Component({
    standalone: true,
    selector: 'app-toaster',
    template: `
        <custom-card [style.background-color]="color" padding-small>
            <div class="flex-row align-start-center">
                <icon [name]="icon" customColor="white" size="28" />
                <div class="flex item-margin-left item-margin-right text-uppercase">
                    {{ message || '' | translate }}
                </div>
                <icon
                    name="close"
                    customColor="white"
                    (click)="remove()"
                    [backButtonBgColor]="styles().strongHoverColor" />
            </div>
            @if (options.progressBar) {
                <div>
                    <div class="toast-progress" [style.width]="width + '%'"></div>
                </div>
            }
        </custom-card>
    `,
    styles: [
        `
            :host {
                display: block;

                .custom-card {
                    border-radius: 0;
                }

                &.ngx-toastr {
                    width: 100%;
                    box-shadow: none;
                    border-radius: 0;
                    background-color: transparent;
                    background-image: none;
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid white;

                    &:not(:first-of-type) {
                    }

                    &:hover {
                        box-shadow: none;
                        cursor: default;
                    }

                    .icon {
                        box-sizing: unset;
                    }

                    .toast-progress {
                        background-color: white;
                        opacity: 0.4;
                    }
                }
            }
        `,
    ],
    imports: [
        CustomCardComponent,
        TranslateModule,
        BgColorVariablePipe,
        IconComponent,
        MatDialogModule,
    ],
})
export class ToasterComponent extends Toast implements OnInit {
    styles: Signal<IStyleVariable> = inject(AppStylesService).styles;

    undoString = 'undo';
    color: string;
    icon: TIcon;

    ngOnInit() {
        switch (this.toastPackage.toastType) {
            case 'toast-success':
                this.color = this.styles().successColor;
                this.icon = 'check';
                break;
            case 'toast-error':
                this.color = this.styles().errorColor;
                this.icon = 'error_outline';
                break;
            case 'toast-warning':
                this.color = this.styles().alertColor;
                this.icon = 'error_outline';
                break;
            case 'toast-info':
                this.color = this.styles().primaryColor;
                this.icon = 'info';
                break;
        }
    }

    action(event: Event) {
        event.stopPropagation();
        this.undoString = 'undid';
        this.toastPackage.triggerAction();
        return false;
    }
}
