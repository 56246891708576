import { IUserManagement, IUserManagementDto, TUser } from '@modules/user-management/interfaces';
import { UserPhoneNumberModel } from '@shared/models';
import { GenericModel } from '@shared/models/generic.model';
import { UserDataAccessModel } from '@shared/models/user-data-access.model';

export class UserManagementModel
    extends GenericModel<IUserManagement, IUserManagementDto>
    implements IUserManagement
{
    country: string;
    dataAccess: UserDataAccessModel;
    id: string;
    language: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: UserPhoneNumberModel;
    phone: UserPhoneNumberModel;
    roles: string[];
    status: string;
    type: TUser;

    constructor(user?: IUserManagementDto) {
        super(user, UserManagementModel);

        this.country = user?.country || '';
        this.dataAccess = new UserDataAccessModel(user?.dataAccess);
        this.id = user?.id || '';
        this.language = user?.language || '';
        this.firstName = user?.firstName || '';
        this.lastName = user?.lastName || '';
        this.email = user?.email || '';
        this.phoneNumber = new UserPhoneNumberModel(user?.phone);
        this.phone = new UserPhoneNumberModel(user?.phone);
        this.roles = user?.roles.map(String) || [];
        this.status = user?.status || '';
        this.type = user?.type || 'customer';
    }

    get fullNumberFormatted(): string {
        return this.phoneNumber.fullNumberFormatted;
    }

    dataTransferObject(): IUserManagementDto {
        const user: IUserManagementDto = this.getOriginalData();

        user.country = this.country;
        user.language = this.language;
        user.firstName = this.firstName;
        user.lastName = this.lastName;
        user.email = this.email;
        user.phoneNumber = this.phoneNumber.fullNumber;
        user.phone = this.phoneNumber.dataTransferObject();
        user.roles = this.roles.map(Number);
        user.status = this.country;
        user.type = this.type;

        if (this.dataAccess) user.dataAccess = this.dataAccess.dataTransferObject();

        return user;
    }
}
