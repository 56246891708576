import { Directive, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserStoreService } from '@shared/services';
import { TUserData, TUserRole } from '@shared/interfaces';

@Directive({
    standalone: true,
    selector: '[display-by-role]',
})
export class DisplayByRoleDirective implements OnInit {
    @Input('display-by-role') allowedRoles: TUserRole;

    private userStoreService: UserStoreService = inject(UserStoreService);
    private viewContainer: ViewContainerRef = inject(ViewContainerRef);

    constructor(private template: TemplateRef<any>) {}

    ngOnInit() {
        if (this.displayTempRef()) {
            this.viewContainer.createEmbeddedView(this.template);
        } else {
            this.viewContainer.clear();
        }
    }

    displayTempRef(): boolean {
        const userRoles: TUserData[] = this.allowedRoles
            .split(',')
            .map(role => role.trim()) as TUserData[];

        for (const role of userRoles) {
            switch (role) {
                case 'globalAdmin':
                    if (this.userStoreService.globalAdmin()) return true;
                    break;
                case 'facilityManager':
                    if (this.userStoreService.facilityManager()) return true;
                    break;
                case 'technical':
                    if (this.userStoreService.technical()) return true;
                    break;
                case 'viewer':
                    if (this.userStoreService.viewer()) return true;
                    break;
                default:
                    // Handle any other roles or invalid roles if needed
                    break;
            }
        }

        return false;
    }
}
