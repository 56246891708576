import { effect, inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguageService } from '@shared/services/app-language.service';
import { translations } from '@shared/utils/translations';

@Injectable({
    providedIn: 'root',
})
export class MatPaginatorI18nService extends MatPaginatorIntl {
    language = inject(AppLanguageService).selected;
    constructor(private translate: TranslateService) {
        super();
        effect(() => {
            if (this.language()) {
                // Translate tooltips from pagination
                this.previousPageLabel = this.translate.instant(
                    translations.table.paginator.previous_page,
                );
                this.nextPageLabel = this.translate.instant(translations.table.paginator.next_page);
                this.lastPageLabel = this.translate.instant(translations.table.paginator.last_page);
                this.firstPageLabel = this.translate.instant(
                    translations.table.paginator.first_page,
                );
                this.itemsPerPageLabel =
                    this.translate.instant(translations.table.paginator.items_page) + ':';
                this.changes.next(); // propagate change
            }
        });
    }
}
