<div class="center-content">
    <custom-card class="flex-column main" glass-effect>
        <auth-header (goBack)="goBack()" />

        <div class="item-margin-top item-margin-bottom flex-column width-100 text-center">
            <div class="text-uppercase font-size-super-xl">kone Elevator Call</div>
            <div class="text-uppercase font-size-super-xl">Manager</div>
        </div>
        <form [formGroup]="formGroup.form" (keydown.enter)="login()" class="item-margin-top">
            <!-- EMAIL -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'acm_email' | translate }}</mat-label>
                <input
                    type="email"
                    name="email"
                    matInput
                    [formControlName]="formGroup.getControlName('username')"
                    autocomplete="username" />
                @if (
                    formGroup.validType('username', 'email') &&
                    !formGroup.validType('username', 'required')
                ) {
                    <mat-error>{{ 'acm_email_invalid' | translate }}</mat-error>
                }
                @if (formGroup.validType('username', 'required')) {
                    <mat-error>{{ 'acm_field_mandatory' | translate }}</mat-error>
                }
            </mat-form-field>
            <!-- PASSWORD -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'acm_password' | translate }}</mat-label>
                <input
                    type="password"
                    name="password"
                    matInput
                    autocomplete="current-password"
                    [formControlName]="formGroup.getControlName('password')" />
                @if (formGroup.invalidValue('password')) {
                    <mat-error>{{ 'acm_field_mandatory' | translate }}</mat-error>
                }
            </mat-form-field>
        </form>

        <div class="align-end-center width-100">
            <a
                (click)="formGroup.getValue('username') ? forgotPassword() : null"
                class="link-btn forgot-pw item-margin-bottom"
                [class.disabled]="!formGroup.getValue('username')">
                {{ 'acm_forgot_password_btn' | translate }}
            </a>
        </div>

        <alert-balloon
            [visible]="!!alertMsg"
            [message]="alertMsg"
            (alertClosed)="alertMsg = ''"
            class="width-100"
            color="warn" />

        <!-- BUTTONS -->
        <div class="item-margin-top">
            <custom-button
                type="flat"
                label="acm_sign_in"
                color="primary"
                fill
                [loadingInside]="loading()"
                (click)="login()" />

            <a (click)="sso()" class="link-btn item-margin-top flex-row align-center-center">
                @if (!loadingCognito) {
                    <span>{{ 'acm_sign_kone_sso' | translate }}</span>
                }
                @if (loadingCognito) {
                    <mat-spinner diameter="17" strokeWidth="2" class="item-margin-left" />
                }
            </a>
        </div>

        <div class="terms-privacy text-color-soft" (click)="openTermsPrivacy()">
            {{ translations.auth.sign_in.terms_data_privacy_link | translate }}
        </div>
    </custom-card>
</div>
