import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    standalone: true,
    name: 'listSelectedTranslationPipe',
})
export class ListSelectedTranslationPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(
        selected: any[],
        translationKey: string,
        placeHolderKey: string,
        getItemTKey: any,
    ): string {
        const text: string = selected
            .map(item => {
                return getItemTKey(translationKey, item, this.translate);
            })
            .join(', '); // display with ', ' after item

        return text || this.translate.instant(placeHolderKey);
    }
}
