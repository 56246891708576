import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppTools {
    //====================================================================================================================
    // OS
    //====================================================================================================================
    static OS: any = {
        isMacintosh() {
            // @ts-ignore
            return window.navigator['userAgentData'].platform.indexOf('Mac') > -1;
        },
        isWindows() {
            // @ts-ignore
            return window.navigator['userAgentData'].platform.indexOf('Win') > -1;
        },
    };

    //====================================================================================================================
    // ARRAY
    //====================================================================================================================
    static array: any = {
        isArray: (arr: any) => {
            if (!arr) return false;
            return Object.prototype.toString.call(arr) === '[object Array]';
        },
    };

    //====================================================================================================================
    // OBJECT
    //====================================================================================================================
    static object: any = {
        isObject: (what: any) => {
            return typeof what === 'object' && what !== null;
        },
        getFirstKey: (object: any) => {
            const keys = Object.keys(object);
            return keys[0];
        },
        getFirstObject: (object: any) => {
            const keys = Object.keys(object);
            return object[keys[0]];
        },
        sizeOf: (object: any) => {
            // return the calculated size
            return ~-encodeURI(JSON.stringify(object)).split(/%..|./).length / 1000000;
        },
        findElement: (object: any, key: string, value: any) => {
            for (const i in object) {
                const element = object[i];
                if (element[key] && element[key] == value) {
                    return element;
                }
            }
        },
        isEmpty: (object: any) => {
            return !Object.keys(object).length;
        },
        getLength: (obj: any) => {
            let size = 0,
                key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        },
        getOrderedPropsAsArray: (obj: any) => {
            const sortable = [];
            for (const att in obj) {
                sortable.push(att);
            }
            sortable.sort((a: any, b: any) => {
                return a[1] - b[1];
            });
            return sortable;
        },
        toArray: (object: any) => {
            const array = [];
            for (const i in object) {
                array.push(object[i]);
            }
            return array;
        },
        keysToArray: (obj: any) => {
            const array = [];
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    array.push(key);
                }
            }
            return array;
        },
    };

    //====================================================================================================================
    // DEEP COPY
    //====================================================================================================================
    public static deepCopyArray(array: any[]): any[] {
        return array.map(item => {
            if (Array.isArray(item)) {
                return this.deepCopyArray(item);
            } else if (typeof item === 'object' && item !== null) {
                return this.deepCopyObject(item);
            } else {
                return item;
            }
        });
    }

    private static deepCopyObject(object: any): any {
        const clonedObject: any = {};
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                if (Array.isArray(value)) {
                    clonedObject[key] = this.deepCopyArray(value);
                } else if (typeof value === 'object' && value !== null) {
                    clonedObject[key] = this.deepCopyObject(value);
                } else {
                    clonedObject[key] = value;
                }
            }
        }
        return clonedObject;
    }

    //====================================================================================================================
    // EXTRAS
    //====================================================================================================================

    public static timeout(ms: number) {
        return new Promise(r => setTimeout(r, ms));
    }

    public static getTextWidth(
        text: string,
        fontSize: string = 'var(--font-normal)',
        maxWidth: number = 0,
    ): number {
        const el = document.createElement('span');
        document.body.appendChild(el);

        el.style.height = 'auto';
        el.style.width = 'auto';
        el.style.position = 'absolute';
        el.style.whiteSpace = 'no-wrap';
        el.style.fontSize = fontSize;
        el.innerHTML = text;

        const width = Math.ceil(el.clientWidth) + 2;
        document.body.removeChild(el);

        return maxWidth && width > maxWidth ? maxWidth : width;
    }
}
