import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { debounceTime, fromEvent, tap, map, startWith } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeviceInfoService {
    private deviceDetector: DeviceDetectorService = inject(DeviceDetectorService);
    private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

    private _loading: WritableSignal<boolean> = signal(true);

    readonly isTablet: Signal<boolean> = toSignal(
        fromEvent(window, 'resize').pipe(
            startWith(false),
            debounceTime(250),
            map(() => this.detectDevice()),
            tap(() => this._loading.set(false)),
        ),
        { initialValue: false },
    );

    fnInit() {
        return new Promise<void>((resolve, reject) => {
            const interval = setInterval(() => {
                if (!this._loading()) {
                    clearInterval(interval);
                    resolve();
                }
            }, 1);
        });
    }

    private detectDevice(): boolean {
        const isTablet: boolean = this.deviceDetector.isTablet(navigator.userAgent);
        const isMobile: boolean = this.deviceDetector.isMobile(navigator.userAgent);
        const isMediumScreen: boolean = this.breakpointObserver.isMatched(Breakpoints.Medium);
        const isSmallScreen: boolean = this.breakpointObserver.isMatched(Breakpoints.Small);
        const isXSmallScreen: boolean = this.breakpointObserver.isMatched(Breakpoints.XSmall);

        return isMobile || isTablet || isSmallScreen || isXSmallScreen;
    }
}
