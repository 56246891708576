import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    numberAttribute,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fadeAnimation } from '@animations/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { ListSelectedTranslationPipe } from '@shared/components/custom-list-selector/pipes/list-selected-translation.pipe';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { SearchInputComponent } from '@shared/components/search-input';
import { ClickStopPropagationDirective } from '@shared/directives/click-stop-propagation.directive';
import { ElementFocusDirective } from '@shared/directives/element-focus.directive';
import { TBgColor, TColor } from '@shared/interfaces';
import { BgColorVariablePipe } from '@shared/pipes';
import { HighlightFilteredTextPipe } from '@shared/pipes/highlight-filtered-text.pipe';
import { AppTools } from '@shared/services/app-tools.service';
import { HotkeysService } from '@shared/services/hotkeys.service';
import { mergeAll, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    standalone: true,
    selector: 'custom-list-selector',
    templateUrl: './custom-list-selector.component.html',
    styleUrls: ['./custom-list-selector.component.css'],
    animations: [fadeAnimation],
    imports: [
        TranslateModule,
        ListSelectedTranslationPipe,
        ClickStopPropagationDirective,
        HighlightFilteredTextPipe,
        ElementFocusDirective,
        MatMenuModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatRippleModule,
        IconComponent,
        MatProgressSpinnerModule,
        MatTooltipModule,
        CdkVirtualForOf,
        SearchInputComponent,
        BgColorVariablePipe,
        CustomButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CustomListSelectorComponent implements OnInit, OnChanges, OnDestroy {
    private translate: TranslateService = inject(TranslateService);
    private hotKeys: HotkeysService = inject(HotkeysService);
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    @Input({ required: true }) itemsList: any; // list of items
    @Input() selectedItems: any | any[]; // multiple selection items
    @Input() selectedItem: any; // single selection item
    @Input() placeHolderKey: string; // placeholder on the input
    @Input() translationKey: string; // text to display -> for simple arrays this is not needed (multiple key e.g 'name + lastname')
    @Input() extendSearch: string[] = []; // keys to search
    @Input() disableItemKey: string; // disable item
    @Input() position: 'above' | 'below' = 'below'; // dropdown position relative to input
    @Input() alertMsg: string; // error text
    @Input({ transform: booleanAttribute }) disable: boolean; // disable input
    @Input({ transform: booleanAttribute }) hasError: boolean; // trigger error message and configuration
    @Input({ transform: booleanAttribute }) multiple: boolean; // multiple items or not
    @Input({ transform: booleanAttribute }) hideErrorSection: boolean; // show/hide error text in bottom of input
    @Input({ transform: booleanAttribute }) behaveAsButton: boolean; // hide dropdown arrow -> behaves like a button
    @Input({ transform: booleanAttribute }) allowQuickSelections: boolean; // buttons on top of selector
    @Input() loading: boolean | null = false; // loading data
    @Input() selectorWidth: string | 'default' | 'auto'; // dropdown width
    @Input({ transform: numberAttribute }) selectorHeight: number = 310; // dropdown height
    @Input() inputHeight: string; // input height
    @Input() inputIcon: TIcon; // input selector icon (left side) -> <icon>
    @Input() inputIconColor: TColor; // input selector icon color
    @Input({ transform: numberAttribute }) inputIconSize: number = 26; //default
    @Input() bgColor: TBgColor; // input selector background color
    @Input() openList$: Observable<void>;
    @Input() closeList$: Observable<void>;

    @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('inputSelector') inputSelector: ElementRef;
    @ViewChild(MatMenuTrigger) listTrigger: MatMenuTrigger;
    @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

    search: string = '';
    inputWidth: string = '';
    listWidth: string = '';
    itemSize: number = 44;
    selected: any[] = []; // to not bind from selected items
    itemHighlightFromKey: any;
    destroyHotKeys = new Subject<void>();
    destroySubscriptions = new Subject<void>();

    readonly searchBtnTKey = 'acm_search';
    readonly selectNoneBtnTKey = 'acm_btn_select_none';
    readonly selectAllBtnTKey = 'acm_btn_select_all';
    readonly dataNotFoundTKey = 'acm_data_not_found';

    ngOnInit(): void {
        if (!this.placeHolderKey) this.placeHolderKey = 'acm_label_none_selected';

        if (this.openList$) {
            this.openList$
                .pipe(takeUntil(this.destroySubscriptions))
                .subscribe(() => this.listTrigger.openMenu());
        }

        if (this.closeList$) {
            this.closeList$
                .pipe(takeUntil(this.destroySubscriptions))
                .subscribe(() => this.listTrigger.closeMenu());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initSelectedItems();
        this.initSettings();
    }

    initSettings(): void {
        this.allowQuickSelections = this.itemsList.length > 1;
        if (this.selectorWidth == 'default') this.inputWidth = '270px';
        else if (this.selectorWidth != '') this.inputWidth = this.selectorWidth;
    }

    initSelectedItems() {
        if (!this.selectedItem) this.selected = this.selectedItems ? [...this.selectedItems] : [];
        if (!this.selectedItems) this.selected = this.selectedItem ? [this.selectedItem] : [];
    }

    /**
     * It sets the width of the list selector.
     */
    setListSelectorWidth(): void {
        if (this.disable) return;

        const getWidth = (width: number): number => {
            if (width < 210) return 210;
            return width;
        };

        const panelEl: HTMLElement = document.querySelector(
            '.mat-mdc-menu-panel.list-selector',
        ) as HTMLElement;
        panelEl.style.minWidth = getWidth(this.inputSelector.nativeElement.offsetWidth) + 'px';
        panelEl.style.maxWidth = getWidth(this.inputSelector.nativeElement.offsetWidth) + 'px';
        this.listWidth = getWidth(this.inputSelector.nativeElement.offsetWidth) + 'px';
    }

    scrollToTop(): void {
        if (this.virtualScroll) this.virtualScroll.scrollToIndex(0, 'smooth');
    }

    selectItem(item: any): void {
        if (this.multiple) {
            const index: number = this.selected.findIndex(
                i => this.getItemId(i) == this.getItemId(item),
            );
            if (index > -1) this.selected.splice(index, 1);
            else this.selected.push(item);
            this.selected = this.selected.slice();
        } else {
            this.selected = [item];
            this.listTrigger.closeMenu(); // close menu for single selection
        }
    }

    async openListSelector(): Promise<void> {
        if (this.behaveAsButton) {
            this.listTrigger.closeMenu();
            return;
        }

        this.initSelectedItems();

        this.itemHighlightFromKey = null;
        this.search = '';

        this.setListSelectorWidth();
        this.subscribeToHotKeys();

        // timeout used to render DOM
        if (!this.itemsList || !this.itemsList.length) return;
        await AppTools.timeout(1);
        const index: number = this.itemsList.findIndex(
            (item: any) =>
                !!this.selected.find(
                    selectedItem => this.getItemId(selectedItem) == this.getItemId(item),
                ),
        );
        this.virtualScroll.scrollToIndex(index <= -1 ? 0 : index, 'smooth'); // scroll to item
    }

    closeListSelector(): void {
        this.destroyHotKeys.next();

        if (this.selectedItems) {
            if (!this.findDifferences(this.selected, this.selectedItems).length) return;
            this.selectedItems = this.selected;
            this.selectedItemsChange.next(this.selectedItems); // array
        } else {
            if (
                this.selected[0] &&
                this.selectedItem &&
                this.getItemTKey(this.selectedItem) == this.getItemTKey(this.selected[0])
            )
                return;
            this.selectedItem = this.selected[0];
            this.selectedItemChange.next(this.selectedItem); // not array
        }
    }

    findDifferences<T>(arr1: T[], arr2: T[]): T[] {
        const differences: T[] = [];
        for (const element of arr1) {
            if (!arr2.includes(element)) differences.push(element);
        }
        for (const element of arr2) {
            if (!arr1.includes(element) && !differences.includes(element))
                differences.push(element);
        }
        return differences;
    }

    subscribeToHotKeys(): void {
        const hotKeys = of(
            this.hotKeys.addShortcut({ keys: 'ArrowDown' }).pipe(map(() => 'ArrowDown')),
            this.hotKeys.addShortcut({ keys: 'ArrowUp' }).pipe(map(() => 'ArrowUp')),
            this.hotKeys.addShortcut({ keys: 'Enter' }).pipe(map(() => 'Enter')),
        );

        hotKeys.pipe(mergeAll(), takeUntil(this.destroyHotKeys)).subscribe(async (key: any) => {
            await this.handleOnKeyDown(key);
        });
    }

    selectAll(): void {
        this.filteredItems
            .filter(item => {
                return (
                    !item[this.disableItemKey] &&
                    !this.selected.find(i => {
                        return this.getItemId(i) === this.getItemId(item);
                    })
                );
            })
            .forEach(item => {
                this.selectItem(item);
            });
    }

    selectNone(): void {
        this.filteredItems
            .filter(item => {
                return (
                    !item[this.disableItemKey] &&
                    !!this.selected.find(i => {
                        return this.getItemId(i) === this.getItemId(item);
                    })
                );
            })
            .forEach(item => {
                this.selectItem(item);
            });
    }

    async handleOnKeyDown(key: 'ArrowDown' | 'ArrowUp' | 'Enter'): Promise<void> {
        let item;
        let index: number = this.filteredItems.findIndex(
            filteredItem =>
                this.getItemId(filteredItem) == this.getItemId(this.itemHighlightFromKey),
        );

        // if highlight flag not found -> find checked item and verified if there is any
        if (index <= -1) {
            index = this.filteredItems.findIndex(
                filteredItem =>
                    !!this.selected.find(
                        selectedItem =>
                            this.getItemId(selectedItem) == this.getItemId(filteredItem),
                    ),
            );
        }

        this.itemHighlightFromKey = null;

        if (index <= -1)
            index = 0; // go to fist
        else if (key == 'ArrowUp' && !index)
            index = this.filteredItems.length - 1; // go to last
        else if (key == 'ArrowDown' && index + 1 === this.filteredItems.length)
            index = 0; // restart
        else if (key == 'ArrowDown' && index >= 0) index++;
        else if (key == 'ArrowUp' && index >= 0) index--;

        item = this.filteredItems[index];

        if (item) {
            this.itemHighlightFromKey = item;
            this.virtualScroll.scrollToIndex(index, 'smooth');
            this.cdr.detectChanges();

            if (key == 'Enter') this.selectItem(item);
        }
    }

    get filteredItems(): any[] {
        const search: string = this.search.toLowerCase().trim();
        return this.itemsList.filter((item: any) => {
            const translatedItem: string = this.translate
                .instant(this.getItemTKey(item))
                .toLowerCase();
            const includesSearch: boolean = translatedItem.includes(search);
            const includesSearchKey: boolean = this.extendSearch.some(searchKey => {
                const searchKeyItem = item[searchKey]
                    ? this.translate.instant(item[searchKey])
                    : '';
                return searchKeyItem.toLowerCase().includes(search);
            });
            return includesSearch || includesSearchKey;
        });
    }

    getViewPortWidth(filterList: any[]): number {
        return filterList.length * this.itemSize > this.selectorHeight
            ? this.selectorHeight
            : filterList.length * this.itemSize;
    }

    isSelected(item: any): boolean {
        return !!this.selected.find(
            selectedItem => this.getItemId(selectedItem) == this.getItemId(item),
        );
    }

    getItemId(item: any): any {
        if (!item) return item;
        return this.decodeTranslationKey(this.translationKey, item, this.translate);
    }

    getItemTKey(item: any): string {
        if (!item) return item;
        return this.decodeTranslationKey(this.translationKey, item, this.translate);
    }

    /**
     * Decode a translation key with variables and replace them with their corresponding values from the 'item' object.
     */
    decodeTranslationKey(
        translationKey: string | null,
        item: any,
        translate: TranslateService,
    ): string {
        if (!translationKey) return item;

        const regex = /\b(\w+)\b/g;
        const variableNames = translationKey.match(regex)?.map(variable => variable.trim()) || [];
        const variables: Record<string, string> = {};

        variableNames.forEach(variable => {
            variables[variable] = translate.instant(item[variable]);
        });

        let result = translationKey;

        for (const [variable, replacement] of Object.entries(variables)) {
            const regExp = new RegExp(`\\b${variable}\\b`, 'g');
            result = result.replace(regExp, replacement);
        }

        return result.replace(/\s*\+\s*/g, ' ').trim() || item[translationKey];
    }

    ngOnDestroy() {
        this.destroySubscriptions.next();
        this.destroySubscriptions.unsubscribe();
        this.destroyHotKeys.next();
        this.destroyHotKeys.unsubscribe();
    }
}
