export const ELEVATOR_CONFIG = {
    AREA_SQUARE: 43,
    AREA_SQUARE_MARGIN: 5,
};

export const TAB_CONFIG = {
    ANIMATION_TIME: 225,
    ICON_SIZE: 26,
    ICON_ARROW_SIZE: 26 + 6,
    BUTTON_MARGIN: 12, // --item-margin
};

export const STEPPER_CONFIG = {
    HEADER_FLEX: '55px',
    CONTENT_FLEX: 'calc(100% - 65px)',
    SPACE_FLEX: '10px',
};
