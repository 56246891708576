import { Component, computed, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from '@core/http/http.service';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { CustomDialogComponent } from '@shared/components/custom-dialog';
import {
    CustomDialogActionsDirective,
    CustomDialogContentDirective,
} from '@shared/components/custom-dialog/directives';
import { UserStoreService } from '@shared/services';
import { translations } from '@utils/translations';

@Component({
    standalone: true,
    selector: 'terms-privacy-dialog',
    template: `
        <custom-dialog
            [label]="translations.auth.sign_in.terms_data_privacy.title"
            [hideActions]="!showActions()"
            [hideCloseButton]="showActions()">
            <ng-template customDialogContent>
                <h2>Terms of use</h2>

                <p>
                    This is an agreement between <b>you</b> and <b>KONE Corporation</b> (with its
                    affiliates, "KONE") and the facility manager or the building owner (the
                    "Manager") which are offering you the Elevator Call Manager and Elevator Call
                    app solely for your convenience and use at the designated building and should
                    not be relied upon for any other purpose (the "Agreement"). The Agreement sets
                    forth the terms of use of the Elevator Call Manager (the "KONE Elevator Call
                    Manager") and the Elevator Call app (the "App") (together the KONE Elevator Call
                    Manager and the App the "Tools") and what personal data KONE and/or the Manager
                    may collect from you when you use the Tools.
                </p>

                <p>
                    Please read this Agreement carefully before using the Tools. By using the Tools,
                    you agree to be bound by this Agreement and to the collection of personal data
                    from you. If you do not accept this Agreement, then you may not use the Tools,
                    and you shall remove the Tools from any devices where you have installed the
                    Tools.
                </p>

                <h2>Use of KONE Elevator Call Manager</h2>

                <p>
                    The Manager has entered into a separate agreement for the Residential KONE flow
                    services with KONE (the "Frame Agreement"). Usage of the KONE Elevator Call
                    Manager is subject to the terms and conditions of such Frame Agreement. By using
                    the KONE Elevator Call Manager, you confirm that you are an authorized
                    representative of the Manager and any actions you take in the KONE Elevator Call
                    Manager are made on behalf of the Manager.
                </p>

                <p>KONE reserves the right to make changes to the KONE Elevator Call Manager.</p>

                <h2>Personal Data</h2>

                <p>
                    The following information may be collected when installing and using the Tools:
                </p>

                <p>
                    Your name, username, your phone number and e-mail address, your apartment, your
                    gender, your physical location when using the remote call functionality,
                    favorite floor, sub-users, and information on the use of the Tools (e.g. usage
                    records and logs, successful and unsuccessful actions, add / delete user, add /
                    modify authorization, failed authoritative actions, failed authentication
                    record, etc.), as well as any other information you yourself have entered into
                    the Tools ("Personal Data"). If you enter any information concerning any other
                    natural person than yourself into the Tools, you must ensure you have received
                    such other person's consent to do so before entering the information into the
                    Tools and inform such person of the personal data processing described in this
                    section of the Agreement.
                </p>

                <p>
                    Processing of Personal Data is necessary for KONE and the Manager to be able to
                    provide you the Tools. If you do not approve of the collection and use of
                    Personal Data, or withdraw the consent you have given to processing of Personal
                    Data, you may not use the Tools.
                </p>

                <p>
                    The Personal Data is being collected by KONE on behalf of the Manager. The
                    Manager is always the controller of the Personal Data. Manager's processing of
                    Personal Data is based on your consent given at the time taking the Tools into
                    use, the contract concerning the use of the Tools in the relevant building
                    and/or any other legal ground informed to you by the Manager. KONE is processing
                    Personal Data on behalf of the Manager based on the agreement concluded between
                    KONE and the Manager, under which KONE is providing Residential Flow services to
                    the Manager. KONE or the Manager may, as allowed by applicable national laws,
                    use Personal Data for its own purposes for the establishment, exercise or
                    defense of legal claims. KONE may to some extent independently process Personal
                    Data for analysis of how the Tools and Residential Flow service is being used,
                    to analyze and correct any possible defects in the Tools or services as well as
                    for product, service and application development. To the extent possible, KONE
                    will anonymize the Personal Data before conducting such actions.
                </p>

                <p>
                    KONE will store Personal Data on behalf of the Manager as long as your user
                    account is active and you are authorized to use the Tools, or as otherwise
                    instructed in writing by the Manager. Certain log data collected of the use of
                    the Tools needs to be retained also after your account has become inactive to
                    ensure an intact audit trail for security purposes. In case Personal Data is
                    processed for the establishment, exercise or defense of legal claims, Personal
                    Data is retained as long as necessary to resolve the case, and thereafter the
                    data is archived and retained as required by applicable national laws and
                    regulations.
                </p>

                <p>
                    The purpose of collecting and processing of the Personal Data is to enable the
                    functioning of the Tools and your use of KONE equipment with the Tools, enable
                    communications between you and the residents, analyze how the Tools and KONE
                    equipment is being used, analyze and correct any possible defects in the Tools
                    and KONE equipment, monitor and ensure the security of the Tools and KONE
                    equipment, security of the building where you use the Tools and security of
                    Personal Data and the end-user data accessible through the Tools, as well as any
                    other purposes of use the Manager has informed you about and/or received your
                    consent for as required by the applicable law. Moreover, KONE or Manager may use
                    your personal data for the purposes of concluding the agreement between KONE and
                    the Manager, such as invoicing, contacting you or providing you the services as
                    agreed as well as monitoring and verifying that such services are provided and
                    used as agreed. Depending on the applicable law, processing of Personal Data may
                    also be based on a legal requirement applicable to the Manager or KONE (such as
                    accounting or data disclosure obligations).
                </p>

                <p>
                    KONE or the Manager may use reliable subcontractors' services when processing
                    Personal Data for the abovementioned purposes. To the extent such subcontractors
                    have access to Personal Data, host Personal Data or otherwise process Personal
                    Data, KONE and/or the Manager (as applicable) shall ensure through contractual
                    arrangements that such subcontractors use the Personal Data only to the extent
                    necessary to perform such services and that subcontractors are committed to
                    relevant confidentiality, data privacy and data security obligations. KONE
                    Corporation may transfer Personal Data to its affiliates and subsidiaries.
                </p>

                <p>
                    Due to the technical and practical requirements, some of the Personal Data may
                    be processed also outside the EU/EEA by the subcontractors, Manager or KONE
                    companies. KONE or Manager (as applicable) ensures that there is a legal basis
                    for such transfers and you can get more information about the data transfers by
                    contacting Manager or KONE.
                </p>

                <p>
                    Your Personal Data may be disclosed to public authorities in situations where
                    the applicable local law explicitly demands and allows for KONE or the Manager
                    to do so.
                </p>

                <p>
                    You have a right to review the Personal Data collected on you, right to have
                    incorrect data corrected, outdated or unnecessary data deleted and right to
                    withdraw your consent. Depending on the applicable law, you may also have right
                    to object processing based on a specific individual circumstance and request
                    data portability. You may use some of these rights through the Tools. If this is
                    not possible, you may use these rights by contacting the Manager.
                </p>

                <p>
                    In the event you have concerns or remarks regarding legality of Personal Data
                    processing, you may lodge a complaint with a competent, local data protection
                    supervisory authority
                </p>

                <h2>Use of the App</h2>

                <h3>4.1 License terms</h3>

                <p>
                    KONE hereby grants you a non-exclusive, non-transferable, non-sublicensable,
                    limited right and license to use the App at the designated building where you
                    have been authorized to do so by the Manager and only to the extent of such
                    authorization. The rights granted herein are subject to your compliance with
                    this Agreement. KONE may modify the App at any time. KONE or the Manager may
                    suspend or discontinue your use of the App, in whole or in part, at any time.
                </p>

                <h3>4.2 No warranty</h3>

                <p>
                    <b
                        >THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE APP ARE PROVIDED
                        TO YOU "AS IS," WITH ALL FAULTS, WITHOUT WARRANTY OF ANY KIND, WITHOUT
                        PERFORMANCE ASSURANCES OR GUARANTEES OF ANY KIND, AND YOUR USE IS AT YOUR
                        SOLE RISK. THE ENTIRE RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES
                        WITH YOU. KONE OR THE MANAGER DO NOT MAKE, AND HEREBY DISCLAIM, ANY AND ALL
                        EXPRESS, IMPLIED OR STATUTORY WARRANTIES, INCLUDING IMPLIED WARRANTIES OF
                        CONDITION, UNINTERRUPTED USE, ACCURACY OF DATA (INCLUDING BUT NOT LIMITED TO
                        LOCATION DATA), MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                        PARTICULAR PURPOSE, NONINFRINGEMENT OF THIRD PARTY RIGHTS, AND WARRANTIES
                        (IF ANY) ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.</b
                    >
                </p>

                <h3>4.3 Limitation of liability</h3>

                <p>
                    <b
                        >YOUR USE OF THE APP IS AT YOUR SOLE RISK AND YOU SHALL BE FULLY RESPONSIBLE
                        FOR ANY UNAUTHORIZED USE OF THE APP AND/OR DEVICES.</b
                    >
                </p>

                <p>
                    <b
                        >THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, KONE OR THE MANAGER
                        SHALL NOT BE LIABLE FOR ANY DIRECT OR INDIRECT DAMAGE RESULTING FROM THE
                        USE, NON-FUNCTIONING OR MALFUNCTION OF THE APP, INCLUDING BUT NOT LIMITED
                        TO, DAMAGES TO PROPERTY, INJURY OR DEATH TO PERSONS, LOSS OF PROFIT, LOSS OF
                        GOODWILL, COMPUTER FAILURE OR MALFUNCTION FROM ANY CAUSES OF ACTION ARISING
                        OUT OF OR RELATED TO THIS AGREEMENT OR THE APP, WHETHER ARISING IN TORT
                        (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE AND WHETHER
                        OR NOT KONE OR THE MANAGER HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES.</b
                    >
                </p>

                <p>
                    <b
                        >SOME COUNTRIES/STATES DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY. THIS
                        LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW
                        IN THE APPLICABLE JURISDICTION. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL
                        RIGHTS, AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
                        JURISDICTION.</b
                    >
                </p>

                <h2>Termination</h2>

                <p>
                    Your rights under this Agreement will automatically terminate if you fail to
                    comply with any term of this Agreement. In case of such termination, KONE or the
                    Manager may immediately revoke your access to the Tools without any
                    compensation. KONE's or the Manager's failure to insist upon or enforce your
                    strict compliance with this Agreement will not constitute a waiver of any of
                    their rights hereunder.
                </p>

                <h2>Governing law</h2>

                <p>
                    This Agreement is governed by the laws of the country where the designated
                    building is located in which you are authorized to use the Tools.
                </p>

                <p>
                    Notwithstanding the above, if the designated building is located in (i) the USA,
                    this Agreement is governed by the laws the state of Illinois, and Illinois
                    courts shall have exclusive jurisdiction over any claims hereunder; and (ii) in
                    Canada, this Agreement is governed by the laws the Province of Ontario, and
                    Ontario courts shall have exclusive jurisdiction over any claims hereunder.
                </p>
            </ng-template>
            @if (showActions()) {
                <ng-template customDialogActions>
                    <custom-button
                        class="item-margin-bottom"
                        type="stroked"
                        color="primary"
                        fill
                        [label]="translations.global.cancel"
                        (click)="cancel()"
                        [disabled]="loading()" />
                    <custom-button
                        type="flat"
                        color="primary"
                        fill
                        [label]="translations.auth.sign_in.terms_data_privacy.agree"
                        [loadingInside]="loading()"
                        (click)="agree()" />
                </ng-template>
            }
        </custom-dialog>
    `,
    imports: [
        CustomDialogComponent,
        CustomDialogContentDirective,
        CustomDialogActionsDirective,
        CustomButtonComponent,
    ],
    styles: `
        :host {
        }
    `,
})
export class TermsPrivacyComponent {
    protected readonly translations = translations;

    private httpService = inject(HttpService);
    private userStoreService = inject(UserStoreService);

    user = inject(UserStoreService).user;

    loading = signal<boolean>(false);

    showActions = computed(() => !!this.user().email);

    constructor(private dialogRef: MatDialogRef<TermsPrivacyComponent>) {}

    cancel() {
        this.dialogRef.close();
    }

    agree() {
        this.userStoreService.updateUser({
            termsAndConditionsAccepted: true,
        });
        this.loading.set(true);
        this.httpService.patchUser(this.userStoreService.user()).subscribe({
            next: result => {
                this.loading.set(false);
                console.log(result);
                this.dialogRef.close('ok');
            },
            error: error => {
                this.loading.set(false);
                console.log(error);
            },
        });
    }
}
