import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormHelper } from '@utils/form-helper/form-helper';
import { FormHelperError } from '@utils/form-helper/form-helper-error';
import { BehaviorSubject, Observable } from 'rxjs';

type TValidatorType =
    | 'email'
    | 'pattern'
    | 'minlength'
    | 'maxlength'
    | 'required'
    | 'invalidDigits'
    | 'phone'
    | 'passwordMismatch';

export type TKeyOptions<T> = {
    [K in keyof T]?: { hasModel: boolean };
};

export interface IBuildOptions {
    isArray: boolean;
}

export class FormHelperGroup<TData> {
    private readonly formGroup$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(
        new FormGroup({}),
    );
    private readonly formHelper: FormHelper<TData> = new FormHelper<TData>();
    private formBuilder: FormBuilder = new FormBuilder();

    private readonly translationPrefix: string;

    private formError = new FormHelperError<TData>('');

    constructor(
        value?: Partial<TData>,
        translationPrefix?: string,
        translationPrefixError?: string,
        mandatoryFields?: (keyof TData)[],
    ) {
        if (value) this.build(value);
        if (mandatoryFields) this.setMandatoryFields(mandatoryFields);
        if (translationPrefix) this.translationPrefix = translationPrefix;

        this.formError.formGroup = this;
        if (translationPrefixError) this.formError.translationPrefix = translationPrefixError;
    }

    get form(): FormGroup {
        return this.formGroup$.getValue() as FormGroup;
    }

    set form(value: FormGroup) {
        this.formGroup$.next(value);
    }

    get controls() {
        return this.form.controls;
    }

    build(value: Partial<TData>, keyOptions?: TKeyOptions<TData>, options?: IBuildOptions): void {
        this.form = this.formBuilder.group(this.formHelper.getForm(value, keyOptions, options));
    }

    getRawValue(): TData {
        return this.form.getRawValue();
    }

    enable(): void {
        this.form.enable();
    }

    disable(): void {
        this.form.disable();
    }

    invalid(): boolean {
        return this.form.invalid;
    }

    valid(): boolean {
        return this.form.valid;
    }

    touched(): boolean {
        return this.form.touched;
    }

    getValue<K extends keyof TData>(target: K): TData[K] {
        return this.getControl(target)?.value;
    }

    setValue<K extends keyof TData>(target: K, value: TData[K], options?: Object): void {
        if (typeof value === 'object' && value !== null && '_original' in value) {
            delete value._original; // Remove _original property if present
        }
        this.getControl(target)?.setValue(value, options);
    }

    changes(): Observable<TData> {
        return this.form.valueChanges;
    }

    valueChanges<K extends keyof TData>(target: K): Observable<TData[K]> {
        return this.getControl(target).valueChanges;
    }

    addControl<K extends keyof TData>(target: K, control: AbstractControl) {
        const key = target as string;
        this.form.addControl(key, control);
    }

    removeControl<K extends keyof TData>(target: K) {
        const key = target as string;
        this.form.removeControl(key);
    }

    setMandatoryFields(fields: (keyof TData)[]): void {
        Object.keys(this.form.controls)
            .filter(key => {
                return fields.includes(key as keyof TData);
            })
            .forEach(key => {
                const prop = key as keyof TData;
                this.getControl(prop)?.setValidators(Validators.required);
                // this.getControl(prop)?.updateValueAndValidity();
            });
    }

    removeMandatoryFields(...fields: (keyof TData)[]): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                const prop = key as keyof TData;
                this.getControl(prop)?.removeValidators(Validators.required);
                this.getControl(prop)?.updateValueAndValidity();
            });
    }

    setValidators<K extends keyof TData>(
        fields: K[],
        validators: ValidatorFn | ValidatorFn[],
    ): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                const prop = key as K;
                this.getControl(prop)?.addValidators(validators);
            });
    }

    hasValidator<K extends keyof TData>(field: K, validators: ValidatorFn): boolean {
        return this.getControl(field)?.hasValidator(validators);
    }

    clearValidators(...fields: (keyof TData)[]): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                const prop = key as keyof TData;
                this.getControl(prop)?.clearValidators();
            });
    }

    enableFields(...fields: (keyof TData)[]): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                const prop = key as keyof TData;
                this.getControl(prop)?.enable();
            });
    }

    disableFields(...fields: (keyof TData)[]): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                const prop = key as keyof TData;
                this.getControl(prop)?.disable();
            });
    }

    removeFields(...fields: (keyof TData)[]): void {
        const keys: string[] = fields.map(key => String(key));
        Object.keys(this.form.controls)
            .filter(key => {
                return keys.includes(key);
            })
            .forEach(key => {
                this.form.removeControl(key);
            });
    }

    invalidValue<K extends keyof TData>(target: K): boolean {
        return this.getControl(target)?.invalid || false;
    }

    getErrorMessage<K extends keyof TData>(target: K): string {
        return this.formError.getMessage(target).message;
    }

    getErrorOptions<K extends keyof TData>(target: K): any {
        return this.formError.getMessage(target).options ?? {};
    }

    validType<K extends keyof TData>(target: K, type: TValidatorType): boolean {
        return (
            (this.getControl(target)?.touched && this.getControl(target)?.hasError(type)) || false
        );
    }

    validValue<K extends keyof TData>(target: K): boolean {
        return this.getControl(target)?.valid || false;
    }

    disabledValue<K extends keyof TData>(target: K): boolean {
        return this.getControl(target)?.disabled || false;
    }

    getControl<K extends keyof TData>(target: K) {
        const key: string = target as string;
        return this.form.controls[key] || null;
    }

    getControlName<K extends keyof TData>(target: K) {
        return target;
    }

    getTranslation<K extends keyof TData>(target: K) {
        return this.translationPrefix ? this.translationPrefix + target.toString() : target;
    }

    getGroupName<K extends keyof TData>(target: K) {
        return target;
    }

    patchValues<K extends keyof TData, TData>(fields: K[] | 'all', data: TData): void {
        Object.keys(this.form.controls)
            .filter(key => {
                if (fields === 'all') return true;
                return fields.includes(key as K);
            })
            .forEach(key => {
                const control = this.form.get(key);
                if (control) {
                    control.patchValue(data[key as K]);
                }
            });
    }

    patchValue<K extends keyof TData>(target: K, data: TData[K]): void {
        this.getControl(target)?.patchValue(data);
    }

    getNestedFormGroup<K extends keyof TData>(target: K): FormHelperGroup<TData[K]> {
        return this.formHelper.nestedFormGroup[target] as FormHelperGroup<TData[K]>;
    }

    getNestedControl<K extends keyof TData, T extends keyof TData[K]>(target: K, control: T) {
        const key = control as string;
        return this.formHelper.nestedFormGroup[target].getControl(target).get(key);
    }

    getNestedFormArray<K extends keyof TData>(target: K) {
        return this.formHelper.nestedFormArray[target] as any; // -> output: TData[K][] -> pretended: TData[K] -> solution: any
    }

    controlInvalid<K extends keyof TData>(target: K): boolean {
        return this.getControl(target)?.invalid;
    }

    isDisabled(): boolean {
        return this.form.disabled;
    }

    validateForm(): void {
        this.form.markAllAsTouched();

        // Object.keys(this.form.controls).filter(key => {
        //    return this.form.get(key)?.errors?.['required'];
        // }).forEach(key => {
        //    const prop = key as keyof TData
        //    if (!this.getValue(prop)) this.form.get(key)?.setErrors(this.formHelper.errorState);
        // });
    }
}
