import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { IUserData, TUserEnv } from '@shared/interfaces/user-data.interface';
import { UserDataModel, UserDataRoleModel } from '@shared/models//user-data.model';

@Injectable({
    providedIn: 'root',
})
export class UserStoreService {
    private _user: WritableSignal<UserDataModel> = signal(new UserDataModel());

    readonly user: Signal<UserDataModel> = this._user.asReadonly();
    readonly isLoggedIn: Signal<boolean> = computed(() => this.user().isLoggedIn);
    readonly environment: Signal<TUserEnv> = computed(() => this.user().environment);
    readonly role: Signal<UserDataRoleModel> = computed(() => this.user().role);
    readonly globalAdmin: Signal<boolean> = computed(() => this.role().userType == 'globalAdmin');
    readonly technical: Signal<boolean> = computed(() => this.role().userType == 'technical');
    readonly facilityManager: Signal<boolean> = computed(
        () => this.role().userType == 'facilityManager',
    );
    readonly viewer: Signal<boolean> = computed(() => this.role().userType == 'viewer');

    updateUser(partialUser: Partial<IUserData>) {
        this._user.update(user => {
            user.update(partialUser);
            // user.role.update({
            //    admin: false,
            //    user: false,
            //    fm: false,
            //    readOnly: true
            // })
            return Object.assign(new UserDataModel(), user);
        });
    }

    clearUser(): void {
        this._user.set(new UserDataModel());
    }
}
