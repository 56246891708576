import { Component, HostBinding, inject, Signal } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { UserStoreService } from '@shared/services/user-store.service';
import { AsyncPipe } from '@angular/common';
import { CustomCardComponent } from '@shared/components/custom-card';
import { FooterColor, PageMargin, PageMarginTop } from '@shared/directives';
import { UserDataModel } from '@shared/models';

@Component({
    standalone: true,
    selector: 'app-footer',
    template: `
        <custom-card class="flex-row" footer-color>
            <div class="flex-column flex">
                <div class="text-color">{{ name }}</div>
                <div class="text-color-soft font-size-sm">
                    {{ 'acm_version' | translate }} : {{ user().appVersion }}
                </div>
            </div>
            <div class="flex"></div>
            <div class="align-center-end">
                <div class="flex-column flex align-center-start">
                    <div class="text-color">&copy; KONE {{ year }}</div>
                    <div class="text-color-soft font-size-sm text-capitalize">
                        {{ 'acm_terms_conditions' | translate }}
                    </div>
                    <div class="text-color-soft font-size-sm text-capitalize">
                        {{ 'acm_data_privacy' | translate }}
                    </div>
                </div>
            </div>
        </custom-card>
    `,
    imports: [TranslateModule, AsyncPipe, CustomCardComponent, FooterColor],
    styles: [
        `
            :host {
                --text-color: rgba(255, 255, 255, 0.96);
                --text-color-soft: rgba(255, 255, 255, 0.6);
            }
        `,
    ],
    hostDirectives: [PageMarginTop, PageMargin],
})
export class FooterComponent {
    userData: UserStoreService = inject(UserStoreService);

    name: string = environment.name;
    year: number = new Date().getFullYear();
    user: Signal<UserDataModel> = this.userData.user;

    @HostBinding('class')
    get class() {
        return 'footer';
    }
}
