import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    inject,
    Inject,
    OnInit,
    signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertBalloonComponent } from '@shared/components/alert-balloon/alert-balloon.component';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { IconComponent } from '@shared/components/icon/icon.component';
import {
    DateRangeFilterService,
    rangesList,
    TRangeType,
} from '@shared/services/date-range-filter.service';

interface IDateFilterButton {
    type: TRangeType;
    text: string;
}

@Component({
    standalone: true,
    selector: 'app-date-filter-dialog',
    templateUrl: './date-filter-dialog.component.html',
    imports: [
        CommonModule,
        IconComponent,
        MatDialogModule,
        TranslateModule,
        MatDatepickerModule,
        MatDividerModule,
        AlertBalloonComponent,
        CustomButtonComponent,
    ],
    providers: [],
    styleUrls: ['./date-filter-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DateFilterDialogComponent implements OnInit, AfterViewInit {
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    private adapter: DateAdapter<any> = inject(DateAdapter);
    private translate: TranslateService = inject(TranslateService);
    private dateRangeFilterService: DateRangeFilterService = inject(DateRangeFilterService);

    maxDate: Date = new Date();
    tipAlert: string;
    rangeType: TRangeType = 'none';
    buttonFilters: IDateFilterButton[] = [];

    @ViewChild('startCalendar') startCalendar: MatCalendar<Date>;
    @ViewChild('endCalendar') endCalendar: MatCalendar<Date>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dateRange: DateRange<Date>,
        private dialogRef: MatDialogRef<DateFilterDialogComponent>,
    ) {
        this.adapter.setLocale(this.translate.getBrowserLang());
    }

    ngOnInit() {
        this.dateRange = new DateRange(this.dateRange.start, this.dateRange.end);
        this.updateRangeType();
        this.setButtonFilters();
    }

    ngAfterViewInit() {
        if (this.rangeType == 'none') this.applyDate('lastQuarter');
        this.styleCalendar();
        this.directCalendarViewToDates();
        this.cdr.detectChanges();
    }

    setButtonFilters(): void {
        this.buttonFilters = rangesList
            .filter(range => range !== 'none')
            .map(range => {
                return {
                    type: range,
                    text: this.dateRangeFilterService.rangeTextMap[range],
                };
            });
    }

    styleCalendar(): void {
        const calendarElements: any = document.getElementsByClassName('mat-calendar');
        for (const calendarEl of calendarElements) {
            calendarEl.classList.add('calendar-adapter');
        }
    }

    rangeDateChange(date: any, end: boolean = false): void {
        if (!date) return;

        if (end) this.dateRange = new DateRange<Date>(this.dateRange.start, date);
        else this.dateRange = new DateRange<Date>(date, this.dateRange.end);

        // check if end date is older than start date
        if (
            this.dateRange.end &&
            this.dateRange.start &&
            this.dateRange.end < this.dateRange.start
        ) {
            this.dateRange = new DateRange<Date>(this.dateRange.end, this.dateRange.end);
            this.startCalendar.activeDate = this.dateRange.end as Date;

            if (!end) this.tipAlert = 'acm_final_date_bigger_start_date_error';
        }

        this.updateRangeType();
    }

    applyDate(type: TRangeType): void {
        const today = new Date();
        let date = new Date();

        switch (type) {
            case 'lastSevenDays':
                date = this.dateRangeFilterService.lastSevenDays;
                break;
            case 'lastMonth':
                date = this.dateRangeFilterService.lastMonth;
                break;
            case 'lastQuarter':
                date = this.dateRangeFilterService.lastQuarter;
                break;
            case 'lastYear':
                date = this.dateRangeFilterService.lastYear;
                break;
            case 'custom':
                return;
        }

        this.rangeType = type;
        this.dateRange = new DateRange<Date>(this.dateRange.start, today);
        this.rangeDateChange(date);
        this.directCalendarViewToDates();
    }

    directCalendarViewToDates(): void {
        this.startCalendar.activeDate = this.dateRange.start as Date;
        this.endCalendar.activeDate = this.dateRange.end as Date;
    }

    updateRangeType(): void {
        this.rangeType = this.dateRangeFilterService.getDateRangeType(this.dateRange);
    }

    get isToday(): boolean {
        const today = new Date();
        return (
            this.dateRange.end?.getDate() === today.getDate() &&
            this.dateRange.end?.getMonth() === today.getMonth() &&
            this.dateRange.end?.getFullYear() === today.getFullYear()
        );
    }

    clearFilter(): void {
        this.dateRange = new DateRange<Date>(null, null);
        this.updateRangeType();
        this.applyFilter();
    }

    applyFilter(): void {
        this.dialogRef.close(this.dateRange);
    }

    protected readonly signal = signal;
}
