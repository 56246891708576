import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { CountsHttpService } from '@core/http/counts-http.service';
import { SitesService } from '@shared/services/sites.service';
import { delay } from 'rxjs/operators';
import { DateRange } from '@angular/material/datepicker';
import { CountModel, CountFrontlineModel } from '@shared/models/';
import { UserStoreService } from '@shared/services/user-store.service';

@Injectable({
    providedIn: 'root',
})
export class FrontlinesCountService {
    private countsHttp: CountsHttpService = inject(CountsHttpService);
    private sitesService: SitesService = inject(SitesService);
    private userStoreService: UserStoreService = inject(UserStoreService);

    private readonly frontLines$ = new BehaviorSubject<CountFrontlineModel[]>([]);
    private readonly selectedFrontLines$ = new BehaviorSubject<CountFrontlineModel[]>([]);
    private readonly selectedFrontLineSites$ = new BehaviorSubject<CountModel[]>([]);
    private readonly loading$ = new BehaviorSubject<boolean>(true);

    readonly frontLinesObservable$ = this.frontLines$.asObservable();
    readonly selectedFrontLinesObservable$ = this.selectedFrontLines$.asObservable();
    readonly selectedFrontLineSitesObservable$ = this.selectedFrontLineSites$.asObservable();
    readonly loadingObservable$ = this.loading$.asObservable();

    async fnInit(dateRange: DateRange<Date | null>): Promise<void> {
        await this.getFrontlinesCount(dateRange);
        if (!this.selectedFrontLines.length) this.selectedFrontLines = [...this.frontLines];
    }

    async getFrontlinesCount(
        dateRange: DateRange<Date | null>,
        skipLoading: boolean = false,
    ): Promise<void> {
        if (!skipLoading) this.loading = true;

        if (this.userStoreService.facilityManager()) {
            const sites: CountModel[] = await lastValueFrom(
                this.countsHttp.getSitesCounts(dateRange).pipe(delay(250)),
            );

            // set dummy frontline to reuse filter selectors logic
            if (sites.length)
                this.frontLines = [
                    new CountFrontlineModel({
                        _id: '',
                        companycode: 'n/a',
                        country: 'Facility Manager',
                        frontLineCountList: sites.map(s => s.getOriginalData()),
                        users: {
                            count: 0,
                            countRegistered: 0,
                            countToday: 0,
                            countRegisteredToday: 0,
                        },
                        sites: { total: 0, active: 0 },
                        fetchDate: new Date().toString(),
                        lastUpdate: new Date().toString(),
                    }),
                ];
        } else {
            this.frontLines = await lastValueFrom(
                this.countsHttp.getFrontlineCounts(dateRange).pipe(delay(250)),
            );
        }

        this.loading = false;
    }

    set frontLines(value: CountFrontlineModel[]) {
        this.frontLines$.next(value);
    }

    set selectedFrontLines(value: CountFrontlineModel[]) {
        this.selectedFrontLines$.next(value);
        this.setSites();
    }

    set selectedFrontLineSites(value: CountModel[]) {
        this.selectedFrontLineSites$.next(value);
    }

    set loading(value: boolean) {
        this.loading$.next(value);
    }

    get frontLines(): CountFrontlineModel[] {
        return this.frontLines$.getValue();
    }

    get selectedFrontLines(): CountFrontlineModel[] {
        return this.selectedFrontLines$.getValue();
    }

    get selectedFrontLineSites(): CountModel[] {
        return this.selectedFrontLineSites$.getValue();
    }

    get loading(): boolean {
        return this.loading$.getValue();
    }

    setSites() {
        const listOfSites: string[] = Array.from(
            new Set(
                this.selectedFrontLines.flatMap(frontLine => {
                    return frontLine.frontLineCountList.map(site => site.id);
                }),
            ),
        );

        this.sitesService.filterSites(listOfSites);
        this.setSelectedFrontLineSites();
    }

    setSelectedFrontLineSites(): void {
        this.selectedFrontLineSites = this.selectedFrontLines.flatMap(frontLine => {
            return frontLine.frontLineCountList.filter(site => {
                return this.sitesService.selectedSites().find(s => s._id == site.id);
            });
        });
    }
}
