import { FormHelperGroup } from '@utils/form-helper/form-helper-group';

interface IErrorMessage {
    message: string;
    options?: { [key: string]: any };
}

export class FormHelperError<T> {
    translationPrefix: string;
    formGroup: FormHelperGroup<T>;

    constructor(translationPrefix: string) {
        this.translationPrefix = translationPrefix;
    }

    getMessage<K extends keyof T>(field: K): IErrorMessage {
        const errors = this.formGroup.getControl(field)?.errors;
        if (!errors)
            return {
                message: '',
            };
        //'pattern' type is not generic, handle on HTML
        const types = ['required', 'email', 'minlength', 'max', 'min'];
        for (let i = 0; i < types.length; i++) {
            const error = errors[types[i]]; // {max: {max: 15, actual: 16}}
            if (!error) continue;

            const message = {
                message: this.translationPrefix + types[i],
                options: error
                    ? Object.keys(error).reduce((options: any, key) => {
                          options[key] = error[key];
                          return options;
                      }, {})
                    : {},
            };
            return message;
        }

        // default
        return {
            message: this.translationPrefix + 'default',
            options: {},
        };
    }
}
