import {
    IUserData,
    IUserDataDto,
    IUserDataRoleDto,
    TUserEnv,
} from '@shared/interfaces/user-data.interface';
import { GenericModel } from '@shared/models/generic.model';
import { TUserData } from '@shared/interfaces';

export class UserDataModel extends GenericModel<IUserData, IUserDataDto> implements IUserData {
    role: UserDataRoleModel;
    email: string;
    isLoggedIn: boolean;
    profilePicture: string;
    sub: string;
    username: string;
    appVersion: string;
    environment: TUserEnv;

    constructor(user: IUserDataDto | null = null) {
        super(user, UserDataModel);

        this.username = user?.username || '';
        this.email = user?.email || '';
        this.sub = user?.sub || '';
        this.appVersion = user?.appVersion || '';
        this.environment = user?.environment || 'local';
        this.role = new UserDataRoleModel(user?.role);

        this.profilePicture = '';
        this.isLoggedIn = false;
    }
}

export class UserDataRoleModel extends GenericModel<IUserDataRoleDto> implements IUserDataRoleDto {
    admin: boolean;
    readOnly: boolean;
    fm: boolean;
    support: boolean;
    user: boolean;

    constructor(role: IUserDataRoleDto | null = null) {
        super(role, UserDataRoleModel);

        this.admin = role?.admin || false;
        this.readOnly = role?.readOnly || false;
        this.user = role?.user || false;
        this.fm = role?.fm || false;
        this.support = role?.user || false;
    }

    get userType(): TUserData {
        if (this.admin) return 'globalAdmin';
        if (this.user) return 'technical';
        if (this.fm) return 'facilityManager';
        if (this.readOnly) return 'viewer';
        return 'viewer';
    }
}
