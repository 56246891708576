import {
    IKenBuilding,
    IKenBuildingDto,
    IKenBuildingGroup,
    IKenBuildingGroupDto,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class KenBuildingModel
    extends GenericModel<IKenBuilding, IKenBuildingDto>
    implements IKenBuilding
{
    buildingId: string;
    kenList: string[];
    thingName: string;
    groups: KenBuildingGroupModel[];

    constructor(device?: IKenBuildingDto) {
        super(device, KenBuildingModel);

        this.buildingId = device?.buildingId || '';
        this.kenList = device?.kenList || [];
        this.thingName = device?.thingName || '';
        this.groups = device?.groups.map(g => new KenBuildingGroupModel(g)) || [];
    }

    dataTransferObject(): IKenBuildingDto {
        return this.getOriginalData();
    }
}

export class KenBuildingGroupModel
    extends GenericModel<IKenBuildingGroup, IKenBuildingGroupDto>
    implements IKenBuildingGroup
{
    deviceId: string;
    groupId: string;

    constructor(buildingGroupDto?: IKenBuildingGroupDto) {
        super(buildingGroupDto, KenBuildingGroupModel);

        this.deviceId = buildingGroupDto?.device_id || '';
        this.groupId = buildingGroupDto?.group_id || '';
    }

    dataTransferObject(): IKenBuildingGroupDto {
        return this.getOriginalData();
    }
}
