import {
    AfterViewInit,
    booleanAttribute,
    Component,
    effect,
    ElementRef,
    HostBinding,
    inject,
    input,
    Input,
    numberAttribute,
    Signal,
    ViewChild,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { IStyleVariable, TColor } from '@shared/interfaces';
import { AppStylesService } from '@shared/services/app-styles.service';

@Component({
    standalone: true,
    selector: 'custom-button',
    templateUrl: './custom-button.component.html',
    styleUrl: './custom-button.component.scss',
    imports: [IconComponent, MatRippleModule, TranslateModule, MatProgressSpinnerModule],
})
export class CustomButtonComponent implements AfterViewInit {
    private appStylesService: AppStylesService = inject(AppStylesService);

    styles: Signal<IStyleVariable> = this.appStylesService.styles;

    title: string = ''; // used to set attribute title in button tag

    // button styles
    @Input() type: 'flat' | 'flat-inverse' | 'stroked';
    @Input() color: TColor;
    @Input({ transform: booleanAttribute }) fill: boolean; // fill space from left to right
    @Input() loadingClass: string;
    @Input({ alias: 'font-size-md', transform: booleanAttribute }) fontSizeMd: boolean;
    @Input({ alias: 'font-size-lg', transform: booleanAttribute }) fontSizeLg: boolean;

    // label
    @Input() label: string;

    // icon
    @Input() icon: TIcon;
    @Input({ transform: numberAttribute }) iconSize: number;

    // states
    loading = input<boolean>(false);
    loadingInside = input<boolean>(false);
    disabled = input<boolean>(false);
    active = input<boolean>(false);
    iconOnly = input<boolean, unknown>(false, {
        transform: booleanAttribute,
    });
    oneLine = input<boolean, unknown>(false, {
        transform: booleanAttribute,
    });
    alignCenter = input<boolean, unknown>(false, {
        transform: booleanAttribute,
    });

    @HostBinding('class.custom-button') customButtonClass = true;

    @HostBinding('class.pointer-events-none')
    get pointerEventNoneClass() {
        return this.loading() || this.disabled() || this.loadingInside();
    }

    @HostBinding('style')
    get style() {
        return {
            '--custom-button-text-color': this.getTextColor(),
            '--custom-button-background-color': this.getBackgroundColor(),
            '--custom-button-hover-color': this.appStylesService.toOpacity(
                undefined,
                this.getTextColor(),
                0.08,
            ),
            '--custom-button-selected-color': this.appStylesService.toOpacity(
                undefined,
                this.getTextColor(),
                0.18,
            ),
            'width': this.fill ? '100%' : '',
        };
    }

    @ViewChild('button', { read: ElementRef }) button: ElementRef;

    constructor() {
        effect(() => {
            if (!this.loading()) {
                this.setText();
            }
        });
    }

    ngAfterViewInit() {
        this.setText();
    }

    getTextColor(): string {
        if (this.disabled()) return this.styles().textColorDisabled;

        const color: string = this.color
            ? this.appStylesService.getColor(this.color)
            : this.styles().textColor;

        switch (this.type) {
            case 'flat':
                return '#FFFFFF';
            case 'flat-inverse':
                return color;
            case 'stroked':
                return color;
        }

        return color;
    }

    getBackgroundColor(): string {
        if (this.disabled() && this.type) return this.styles().disabledColor;

        switch (this.type) {
            case undefined:
                return 'transparent';
            case 'flat-inverse':
                return this.styles().hoverColor;
        }

        return this.appStylesService.getColor(this.color);
    }

    setText(): void {
        setTimeout(() => {
            if (this.button) this.title = this.button.nativeElement.textContent;
        }, 10);
    }
}
