import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[drop-file]',
})
export class DropFileDirective {
    @HostBinding('class.file-over') fileOver: boolean;
    @Output() fileDropped: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('dragover', ['$event'])
    onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event'])
    ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
