import { IFrontLine, ILanguage } from '@modules/user-management/interfaces';

export class FrontLineModel implements IFrontLine {
    area_code: string;
    code: string;
    dateformat: string;
    langs: ILanguage[];
    name: string;
    translation: string;
    units: string;

    constructor(frontLine: IFrontLine | null = null) {
        this.area_code = frontLine?.area_code || '';
        this.code = frontLine?.code || '';
        this.dateformat = frontLine?.dateformat || '';
        this.name = frontLine?.name || '';
        this.translation = frontLine?.translation || '';
        this.units = frontLine?.units || '';
        this.langs = frontLine?.langs.map(l => new LanguageModel(l)) || [];
    }
}

export class LanguageModel implements ILanguage {
    code: string;
    translation: string;

    constructor(language: ILanguage | null = null) {
        this.code = language?.code || '';
        this.translation = language?.translation || '';
    }
}
