export class GenericModel<T, TDto = T> {
    private readonly _original: TDto | null;
    private readonly dataModel: new (...args: any) => T;

    readonly _internalId: string = '';

    constructor(data: TDto | null = null, dataModel?: new (...args: any) => T) {
        this._original = this.deepClone(data);
        this._internalId = this.generateInternalId();
        if (dataModel) this.dataModel = dataModel;
    }

    update(values: Partial<T>): void {
        Object.assign(this, values);
    }

    revertUpdate(): void {
        Object.assign(this, new this.dataModel(this._original));
    }

    getOriginalData(): TDto {
        return Object.assign({}, this._original);
    }

    private deepClone(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj; // If it's not an object or is null, return as-is
        }

        if (Array.isArray(obj)) {
            const clonedArray = [];
            for (let i = 0; i < obj.length; i++) {
                clonedArray[i] = this.deepClone(obj[i]);
            }
            return clonedArray;
        }

        const clonedObject: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                clonedObject[key] = this.deepClone(obj[key]);
            }
        }
        return clonedObject;
    }

    private generateInternalId() {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const uniqueId = (array[0] % 900000) + 100000; // Ensure it's a 6-digit number
        return uniqueId.toString();
    }
}

export class GenericObjectModel<T, TDto> {
    protected readonly _original: TDto | null;

    constructor(data: TDto | null = null) {
        this._original = data;
    }

    update<U extends Object>(value: U): void {
        // empty all keys if value is empty
        if (!Object.keys(value).length) {
            for (const key in this) {
                if (key === '_original') continue;
                delete this[key];
            }
        }

        // Remove keys from data that do not exist in value
        for (const key in value) {
            if (!this?.hasOwnProperty(key)) {
                // @ts-ignore
                delete this[key];
            }
        }

        for (const key in value) {
            // @ts-ignore
            this[key] = value[key];
        }
    }

    revertUpdate(): void {
        // Remove keys from data that do not exist in _original
        for (const key in this) {
            if (!this._original?.hasOwnProperty(key)) delete this[key];
        }

        // Revert the values of existing keys
        for (const key in this._original) {
            // @ts-ignore
            this[key] = this._original[key];
        }
    }

    dataTransferObject(): TDto {
        const dataDto: TDto = {} as TDto;

        for (const key in this) {
            if (key === '_original') continue;

            if (this[key] instanceof GenericObjectModel) {
                // @ts-ignore
                dataDto[key] = this[key].dataTransferObject();
            } else {
                // @ts-ignore
                dataDto[key] = this[key];
            }
        }

        return dataDto;
    }
}
