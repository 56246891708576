import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'string',
})
export class StringPipe implements PipeTransform {
    transform(value: any): string {
        return value?.toString() || '';
    }
}
