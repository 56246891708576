import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'highlightFilteredText',
})
export class HighlightFilteredTextPipe implements PipeTransform {
    transform(text: string, search: string | null): string {
        const searchValue: string = search || '';
        const pattern = searchValue
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
            .split(' ')
            .filter(t => t.length > 0)
            .join('|');
        const regex = new RegExp(pattern, 'gi');
        const match = text.match(regex);
        if (!match) {
            return text;
        }
        return search
            ? text.replace(regex, word => `<span class="primary-color">${word}</span>`)
            : text;
    }
}
