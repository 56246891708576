import { Component, inject, signal } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { TermsPrivacyComponent } from '@app/pages/auth/components/terms-privacy/terms-privacy.component';
import { IAuthForm } from '@app/pages/auth/interfaces';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { AlertBalloonComponent } from '@shared/components/alert-balloon/alert-balloon.component';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { CustomCardComponent } from '@shared/components/custom-card';
import { GlassEffectDirective } from '@shared/directives';
import { AppStarterService, UserStoreService } from '@shared/services';
import { translations } from '@shared/utils/translations';
import { FormHelperGroup } from '@utils/form-helper';
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import { lastValueFrom } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    imports: [
        TranslateModule,
        CustomCardComponent,
        GlassEffectDirective,
        AuthHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        AlertBalloonComponent,
        MatProgressSpinnerModule,
        MatInputModule,
        CustomButtonComponent,
    ],
    styleUrls: ['../../auth.component.scss'],
})
export class SignInComponent {
    protected readonly translations = translations;
    private appStarterService: AppStarterService = inject(AppStarterService);
    private router: Router = inject(Router);
    private matDialog = inject(MatDialog);

    user = inject(UserStoreService).user;

    loadingCognito: boolean = false;
    alertMsg: string = '';
    formGroup: FormHelperGroup<IAuthForm> = new FormHelperGroup();

    loading = signal(false);

    forgotPassword(): void {
        this.router
            .navigate(['auth/forgot-password/' + this.formGroup.getValue('username')])
            .then();
    }

    constructor() {
        this.formGroup.build({
            username: '',
            password: '',
        });

        this.formGroup.setMandatoryFields(['username', 'password']);
        this.formGroup.setValidators(['username'], Validators.email);
    }

    goBack(): void {
        this.formGroup.enableFields('username');
        this.alertMsg = '';
    }

    sso(): void {
        this.loadingCognito = true;
        signInWithRedirect({
            provider: {
                custom: environment.aws.provider,
            },
        });
    }

    login(): void {
        if (this.loading() || this.loadingCognito) return; // while loading ignore any attempt to login again

        this.formGroup.form.markAllAsTouched();

        if (this.formGroup.invalid()) return;

        if (this.koneEmail(this.formGroup.getRawValue().username)) {
            this.alertMsg = translations.auth.error.kone_email;
            return;
        }

        this.alertMsg = '';
        this.loading.set(true);

        signIn({
            username: this.formGroup.getRawValue().username,
            password: this.formGroup.getRawValue().password,
        }).then(
            async () => {
                const loggedIn = await this.appStarterService.initUser(true);
                if (!loggedIn) {
                    this.alertMsg = translations.acm_login_error;
                    this.loading.set(false);
                }
            },
            error => {
                this.alertMsg = error.message ?? translations.acm_login_error;
                this.loading.set(false);
            },
        );
    }

    koneEmail(email: string): boolean {
        const pattern = /^[a-z]+\.[a-z]+@kone\.com$/i;
        return pattern.test(email);
    }

    async openTermsPrivacy() {
        const dialogRef = this.matDialog.open(TermsPrivacyComponent, {
            disableClose: true,
            minWidth: 600,
        });

        return await lastValueFrom(dialogRef.afterClosed());
    }
}
