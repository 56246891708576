import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateRange } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

const availableRanges = [
    'lastSevenDays',
    'lastMonth',
    'lastQuarter',
    'lastYear',
    'custom',
    'none',
] as const;
export const rangesList = availableRanges.map(tab => tab);
export type TRangeType = (typeof availableRanges)[number];
export type TRangeText = {
    [key in TRangeType]: string;
};

@Injectable({
    providedIn: 'root',
})
export class DateRangeFilterService {
    private datePipe: DatePipe = inject(DatePipe);

    private readonly dateRange$ = new BehaviorSubject<DateRange<Date | null>>(
        new DateRange(null, null),
    );
    private readonly dateRangeText$ = new BehaviorSubject<string>('acm_select_range');

    readonly dateRangeObservable$ = this.dateRange$.asObservable();
    readonly dateRangeTextObservable$ = this.dateRangeText$.asObservable();

    rangeTextMap: TRangeText = {
        lastSevenDays: 'acm_last_seven_days',
        lastMonth: 'acm_last_month',
        lastQuarter: 'acm_last_quarter',
        lastYear: 'acm_last_year',
        none: 'acm_select_range',
        custom: 'acm_custom',
    };

    set dateRange(value: DateRange<Date | null>) {
        this.dateRange$.next(value);
        this.dateRangeText = this.getDateRangeText(value);
    }

    set dateRangeText(value: string) {
        this.dateRangeText$.next(value);
    }

    get dateRange(): DateRange<Date | null> {
        return this.dateRange$.getValue();
    }

    get dateRangeText(): string {
        return this.dateRangeText$.getValue();
    }

    getDateRangeType(dateRange: DateRange<Date | null>): TRangeType {
        const startDate: Date | null = dateRange.start;
        const endDate: Date | null = dateRange.end;

        if (!startDate || !endDate) return 'none';

        if (!this.isSameDay(endDate, this.today)) return 'custom';

        if (this.isSameDay(startDate, this.lastSevenDays)) return 'lastSevenDays';
        if (this.isSameDay(startDate, this.lastMonth)) return 'lastMonth';
        if (this.isSameDay(startDate, this.lastQuarter)) return 'lastQuarter';
        if (this.isSameDay(startDate, this.lastYear)) return 'lastYear';

        return 'custom';
    }

    isSameDay(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    getDateRangeText(dateRange: DateRange<Date | null>) {
        const type: TRangeType = this.getDateRangeType(dateRange);
        if (type == 'custom')
            return (
                this.datePipe.transform(dateRange.start) +
                ' - ' +
                this.datePipe.transform(dateRange.end)
            );
        return this.rangeTextMap[type];
    }

    get today(): Date {
        return new Date();
    }

    get lastSevenDays(): Date {
        return new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7);
    }

    get lastMonth(): Date {
        return new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate());
    }

    get lastQuarter(): Date {
        return new Date(this.today.getFullYear(), this.today.getMonth() - 3, 1);
    }

    get lastYear(): Date {
        return new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDate());
    }
}
