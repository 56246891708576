import {
    booleanAttribute,
    Component,
    ElementRef,
    HostBinding,
    inject,
    Input,
    Signal,
} from '@angular/core';
import { CustomCardService } from '@shared/components/custom-card/custom-card.service';
import { NothingFoundComponent } from '@shared/components/nothing-found/nothing-found.component';
import { SecondaryLayerColor } from '@shared/directives';

@Component({
    standalone: true,
    selector: 'custom-card-content',
    template: `
        @if (visibleContent()) {
            @if (hasError) {
                <nothing-found
                    [icon]="'search_off'"
                    [message]="errorMessage"
                    [messageHint]="errorMessageHint"
                    secondary-layer-color />
            } @else {
                <ng-content></ng-content>
            }
        }
    `,
    styleUrls: ['./custom-card-content.component.scss'],
    imports: [NothingFoundComponent, SecondaryLayerColor],
})
export class CustomCardContentComponent {
    private hostElement: ElementRef = inject(ElementRef);

    @Input({ transform: booleanAttribute }) hasError: boolean;
    @Input({ alias: 'remove-margin-top', transform: booleanAttribute }) removeMarginTop: boolean;
    @Input() errorMessage: string;
    @Input() errorMessageHint: string;
    @Input({ transform: booleanAttribute }) flex: boolean;

    @HostBinding('class')
    get class() {
        return {
            'margin-top': !this.removeMarginTop,
        };
    }

    @HostBinding('style')
    get style() {
        const computedStyle = window.getComputedStyle(this.hostElement.nativeElement);
        const displayFlex = computedStyle.getPropertyValue('display') == 'flex';
        return displayFlex || this.flex ? '' : 'display: block';
    }

    visibleContent: Signal<boolean> = inject(CustomCardService).visibleContent;
}
