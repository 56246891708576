import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    booleanAttribute,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CustomCardService } from '@shared/components/custom-card/custom-card.service';
import { DestroyerComponent } from '@shared/components/destroyer-component/destroyer.component';

@Component({
    standalone: true,
    selector: 'custom-card',
    template: ` <ng-content></ng-content> `,
    styleUrls: ['./custom-card.component.scss'],
    providers: [CustomCardService],
    animations: [
        trigger('customCardExpanded', [
            state(
                'collapsed',
                style({ height: 'var(--header-height)', minHeight: 'var(--header-height)' }),
            ),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class CustomCardComponent extends DestroyerComponent implements OnChanges {
    private hostElement: ElementRef = inject(ElementRef);
    private customCardService: CustomCardService = inject(CustomCardService);

    @Input({ alias: 'full-height', transform: booleanAttribute }) fullHeight: boolean | null;
    @Input({ alias: 'remove-padding-bottom', transform: booleanAttribute }) removePaddingBottom:
        | boolean
        | null;
    @Input({ alias: 'remove-background-color', transform: booleanAttribute }) removeBgColor:
        | boolean
        | null;
    @Input({ alias: 'remove-padding', transform: booleanAttribute }) removePadding: boolean | null;
    @Input({ alias: 'remove-padding-inline', transform: booleanAttribute }) removePaddingInline:
        | boolean
        | null;
    @Input({ alias: 'padding-small', transform: booleanAttribute }) paddingSmall: boolean | null;
    @Input({ alias: 'padding-super-small', transform: booleanAttribute }) paddingSuperSmall:
        | boolean
        | null;
    @Input({ alias: 'padding-inline-small', transform: booleanAttribute }) paddingInlineSmall:
        | boolean
        | null;
    @Input({ alias: 'overflow-unset', transform: booleanAttribute }) overflowUnset: boolean | null;
    @Input({ alias: 'hover-background', transform: booleanAttribute }) allowBackground:
        | boolean
        | null;
    @Input({ alias: 'width-100', transform: booleanAttribute }) width100: boolean | null;
    @Input({ transform: booleanAttribute }) sticky: boolean; // only sticks when window scroll is trigger
    @Input({ alias: 'sticky-fixed', transform: booleanAttribute }) fixedSticky: boolean; // always sticky
    @Input({ transform: booleanAttribute }) border: boolean; // closed / opened
    @Input({ transform: booleanAttribute }) expanded: boolean; // closed / opened
    @Input({ transform: booleanAttribute }) flex: boolean;

    @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    isSticky: boolean = false;

    constructor() {
        super();

        effect(() => {
            this.expandedChange.emit(this.customCardService.state());
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['expanded']) {
            if (this.expanded) this.customCardService.open();
            else this.customCardService.close();
        }
    }

    @HostBinding('class')
    get class() {
        return {
            'custom-card': true,
            'remove-padding-bottom': this.removePaddingBottom,
            'remove-background-color': this.removeBgColor,
            'remove-padding': this.removePadding,
            'remove-padding-inline': this.removePaddingInline,
            'padding-small': this.paddingSmall,
            'padding-inline-small': this.paddingInlineSmall,
            'padding-super-small': this.paddingSuperSmall,
            'overflow-unset': this.overflowUnset,
            'hover-background': this.allowBackground,
            'border': this.border,
            'full-height': this.fullHeight,
            'width-100': this.width100,
            'sticky': this.isSticky || this.fixedSticky,
        };
    }

    @HostBinding('style')
    get style() {
        const computedStyle = window.getComputedStyle(this.hostElement.nativeElement);
        const displayFlex = computedStyle.getPropertyValue('display') == 'flex';
        return displayFlex || this.flex ? '' : 'display: block';
    }

    @HostBinding('@customCardExpanded')
    get animationState() {
        return this.customCardService.state() ? 'expanded' : 'collapsed';
    }

    @HostListener('@customCardExpanded.done', ['$event'])
    onAnimationDone(event: AnimationEvent) {
        if (!this.customCardService.state()) this.customCardService.hideContent();
    }

    @HostListener('window:scroll', [])
    onScroll(): void {
        if (!this.sticky) return;

        const rect = this.hostElement.nativeElement.getBoundingClientRect();
        this.isSticky = rect.y <= 0;
    }
}
