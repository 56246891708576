import { FormHelperArray } from '@utils/form-helper/form-helper-array';
import { FormHelperGroup, IBuildOptions, TKeyOptions } from '@utils/form-helper/form-helper-group';

type INestedFormGroup<T> = {
    [key in keyof T]: FormHelperGroup<T>;
};
type INestedFormArray<T> = {
    [key in keyof T]: FormHelperArray<T>;
};

export class FormHelper<TData> {
    nestedFormGroup: INestedFormGroup<TData> = {} as INestedFormGroup<TData>;
    nestedFormArray: INestedFormArray<TData> = {} as INestedFormArray<TData>;
    errorState = { incorrect: true };

    getForm(value: any, propOptions?: TKeyOptions<TData>, options?: IBuildOptions) {
        const form: any = [];

        if (!value) return form;

        Object.keys(value).forEach(key => {
            if (['_original'].includes(key)) return;

            let dataValue = value[key];
            const prop = key as keyof TData;
            const hasModel: boolean = propOptions?.[prop]?.hasModel ?? false;

            if (Array.isArray(dataValue)) {
                if (!hasModel && dataValue.every((item: any) => typeof item === 'string')) {
                    dataValue = [dataValue];
                } else if (!hasModel && dataValue.every((item: any) => typeof item === 'number')) {
                    dataValue = [dataValue];
                } else {
                    this.nestedFormArray[prop] = new FormHelperArray<TData>();
                    this.nestedFormArray[prop].build(dataValue);
                    dataValue = this.nestedFormArray[prop].form;
                }
            } else if (typeof dataValue === 'object' && dataValue !== null) {
                this.nestedFormGroup[prop] = new FormHelperGroup<TData>();
                this.nestedFormGroup[prop].build(dataValue);
                dataValue = this.nestedFormGroup[prop].form;
            } else {
                dataValue = [dataValue];
            }

            if (!options?.isArray) form[prop] = dataValue;
            else form.unshift(dataValue);
        });

        return form;
    }
}
