import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CustomCardComponent } from '@shared/components/custom-card';
import { IconComponent } from '@shared/components/icon/icon.component';
import { GlassEffectDirective } from '@shared/directives';

@Component({
    standalone: true,
    selector: 'app-error',
    templateUrl: './error.component.html',
    imports: [
        TranslateModule,
        CustomCardComponent,
        AuthHeaderComponent,
        IconComponent,
        GlassEffectDirective,
        RouterOutlet,
    ],
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {}
