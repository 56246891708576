import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DateFilterSelectorComponent } from '@shared/components/date-filter-selector';
import { DestroyerComponent } from '@shared/components/destroyer-component/destroyer.component';
import { FrontlinesSelectorComponent } from '@shared/components/frontlines-selector/frontlines-selector.component';
import { SitesSelectorComponent } from '@shared/components/sites-selector/sites-selector.component';
import { UserPictureProfileComponent } from '@shared/components/user-picture-profile/user-picture-profile.component';
import { UserProfileDialogComponent } from '@shared/components/user-profile-dialog';
import { PageMarginBottom } from '@shared/directives';
import { TRouteAccess } from '@shared/models//route-management.model';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { DeviceInfoService } from '@shared/services/device-info.service';

import { HeaderService } from './services/header.service';

@Component({
    standalone: true,
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        CommonModule,
        MatToolbarModule,
        SitesSelectorComponent,
        FrontlinesSelectorComponent,
        DateFilterSelectorComponent,
        UserPictureProfileComponent,
    ],
    hostDirectives: [PageMarginBottom],
})
export class HeaderComponent extends DestroyerComponent implements OnInit, OnDestroy {
    protected readonly environment = environment;

    public router: Router = inject(Router);
    private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
    private header: HeaderService = inject(HeaderService);
    readonly UserProfileDialogComponent = UserProfileDialogComponent;

    titleMap: TRouteAccess = { ...this.routeManagement.routeAccess };
    title: string = '';

    isTablet: Signal<boolean> = inject(DeviceInfoService).isTablet;
    middleText$ = this.header.middleTextObservable$;

    constructor() {
        super();

        // this.header.titleObservable$
        //     .pipe(this.takeUntilDestroyed())
        //     .subscribe((value: ITitleName) => {
        //         if (value.titleTKey) this.titleMap[value.page].title = value.titleTKey;
        //         if (value.subTitleTKey) this.titleMap[value.page].subtitle = value.subTitleTKey;
        //     });
    }

    ngOnInit(): void {
        this.header.middleText = '';
    }

    @HostBinding('class.header')
    get classes() {
        return true; // track element for some views (e.g. track height)
    }

    @HostBinding('class.tablet')
    get tabletClass() {
        return this.isTablet();
    }
}
