import { IRoleRight, IRoleRightDto } from '@modules/user-management/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class RoleRightModel extends GenericModel<IRoleRight, IRoleRightDto> implements IRoleRight {
    id?: string;
    manage: boolean;
    setting: string;
    view: boolean;

    constructor(role: IRoleRightDto | null = null) {
        super(role, RoleRightModel);

        this.id = role?.id || new Date().getTime().toString();
        this.manage = role?.manage || false;
        this.view = role?.view || false;
        this.setting = role?.setting || '';
    }

    dataTransferObject(): IRoleRightDto {
        const rightDto: IRoleRightDto = this.getOriginalData();

        rightDto.manage = this.manage;
        rightDto.view = this.view;
        rightDto.setting = this.setting;

        return rightDto;
    }
}
