import { IGroup, IGroupDto, IGroupSite, IGroupSiteDto } from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class GroupModel extends GenericModel<IGroup, IGroupDto> implements IGroup {
    id: string;
    clientId: string;
    description: string;
    enabled: boolean;
    name: string;
    origin: string;
    public: boolean;
    sites: GroupSiteModel[];
    type: string;
    floors: number;
    users: number;
    cascadingEnabled: boolean;
    memberCount: number;
    cascadingUserCount: number;

    constructor(group: IGroupDto | null = null, siteId?: string) {
        super(group, GroupModel);

        this.id = group?.id || '';
        this.clientId = group?.clientId || '';
        this.description = group?.description || '';
        this.enabled = group?.enabled || true;
        this.cascadingEnabled = group?.cascadingEnabled ?? false;
        this.name = group?.name || '';
        this.memberCount = group?.memberCount ?? 0;
        this.cascadingUserCount = group?.cascadingUserCount ?? 0;
        this.sites = group?.sites.map(s => new GroupSiteModel(s, siteId)) || [
            new GroupSiteModel(null, siteId),
        ];
        this.type = group?.type || 'group';

        if (group?.hasOwnProperty('public')) this.public = group.public;
        if (group?.hasOwnProperty('origin')) this.origin = group.origin;
    }

    get disabled(): boolean {
        return !this.enabled || this.origin == 'sfs';
    }

    dataTransferObject(): IGroupDto {
        const groupDto: IGroupDto = this.getOriginalData();

        groupDto.clientId = this.clientId;
        groupDto.description = this.description;
        groupDto.enabled = this.enabled;
        groupDto.name = this.name;
        groupDto.sites = this.sites.map(gs => gs.dataTransferObject());
        groupDto.type = this.type;

        if (this.public !== undefined) groupDto.public = this.public;
        if (this.origin !== undefined) groupDto.origin = this.origin;

        return groupDto;
    }

    dataTransferObjectUpdate(): IGroupDto {
        const groupDto: IGroupDto = {} as IGroupDto;

        groupDto.description = this.description;
        groupDto.enabled = this.enabled;
        groupDto.name = this.name;
        groupDto.cascadingEnabled = this.cascadingEnabled;
        groupDto.sites = this.sites.map(gs => gs.dataTransferObject());

        return groupDto;
    }
}

export class GroupSiteModel extends GenericModel<IGroupSite, IGroupSiteDto> implements IGroupSite {
    areas: string[];
    callType: string[];
    id: string;
    tier: string;

    constructor(groupSite: IGroupSiteDto | null = null, siteId?: string) {
        super(groupSite, GroupSiteModel);

        this.id = groupSite?.id || siteId || '';
        this.callType = groupSite?.callType || [];
        this.areas = groupSite?.areas || [];
        this.tier = groupSite?.tier || 'basic';
    }

    dataTransferObject(): IGroupSiteDto {
        return {
            ...this.getOriginalData(),
            id: this.id,
            callType: this.callType,
            areas: this.areas,
            tier: this.tier,
        };
    }
}
