import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserStoreService } from '@shared/services/user-store.service';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { AppService } from '@shared/services/app.service';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const AuthGuard: (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => Observable<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userData = inject(UserStoreService);
    const router = inject(Router);
    const routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
    const appService: AppService = inject(AppService);
    const currentPath = state.url.split('/')[1];

    return appService.loadingObservable$.pipe(
        filter(loading => !loading),
        map(loading => {
            if (userData.isLoggedIn()) {
                if (currentPath === 'auth') {
                    router.navigate([routeManagement.defaultRoute]);
                    return false;
                }
                return true;
            } else if (!appService.error()) {
                if (
                    !state.url.includes('/auth/signin') &&
                    !state.url.includes('/auth/forgot-password') &&
                    state.url != '/auth/change-password'
                ) {
                    router.navigate(['auth/signin']).then();
                    return false;
                }
                return true;
            }

            return false;
        }),
    );
};
