import {
    IKenDevice,
    IKenDeviceAddress,
    IKenDeviceAddressDto,
    IKenDeviceContractDetails,
    IKenDeviceContractDetailsDto,
    IKenDeviceDto,
    IKenDeviceLocation,
    IKenDeviceLocationDto,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class KenDeviceModel extends GenericModel<IKenDevice, IKenDeviceDto> implements IKenDevice {
    address: KenDeviceAddressModel;
    location: KenDeviceLocationModel;
    contractDetails: KenDeviceContactModel;
    koneId: string;

    constructor(device?: IKenDeviceDto) {
        super(device, KenDeviceModel);

        this.address = new KenDeviceAddressModel(device?.address);
        this.location = new KenDeviceLocationModel(device?.location);
        this.contractDetails = new KenDeviceContactModel(device?.contractDetails);
        this.koneId = device?.thingName || '';
    }

    dataTransferObject(): IKenDeviceDto {
        const deviceDto: IKenDeviceDto = this.getOriginalData();

        deviceDto.address = this.address.dataTransferObject();
        deviceDto.location = this.location.dataTransferObject();

        return deviceDto;
    }
}

export class KenDeviceAddressModel
    extends GenericModel<IKenDeviceAddress, IKenDeviceAddressDto>
    implements IKenDeviceAddress
{
    city: string;
    countryCode: string;
    street: string;
    district: null;
    postalCode: string;
    houseNumber?: number;
    name: string;
    region: string;

    constructor(addressDto?: IKenDeviceAddressDto) {
        super(addressDto, KenDeviceAddressModel);

        this.name =
            addressDto?.name
                .filter(name => name != ' ' && name)
                .reduce((text: string, name: string) => (text += ' ' + name), '') || '';
        this.city = addressDto?.city.join(' ') || '';
        this.countryCode = addressDto?.countryCode || '';
        this.district = addressDto?.district || null;
        this.houseNumber = addressDto?.houseNumber || undefined;
        this.postalCode = addressDto?.postalCode || '';
        this.region = addressDto?.region || '';
        this.street = addressDto?.street || '';
    }

    dataTransferObject(): IKenDeviceAddressDto {
        return this.getOriginalData();
    }
}

export class KenDeviceContactModel
    extends GenericModel<IKenDeviceContractDetails, IKenDeviceContractDetailsDto>
    implements IKenDeviceContractDetails
{
    serviceLevel: string;
    startDate: string;
    endDate: string;

    constructor(addressDto?: IKenDeviceContractDetailsDto) {
        super(addressDto, KenDeviceContactModel);

        this.serviceLevel = addressDto?.serviceLevel || '';
        this.startDate = addressDto?.startDate || '';
        this.endDate = addressDto?.endDate || '';
    }

    get exists(): boolean {
        if (!this.endDate || !this.startDate) {
            return false; // 'No existing contract for the provided KEN'
        }

        const currentDate = new Date();
        const startDate = new Date(this.startDate);
        return currentDate >= startDate;
    }

    get expired(): boolean {
        if (!this.endDate) return false;

        const currentDate = new Date();
        const contractEndDate = new Date(this.endDate);

        return currentDate > contractEndDate;
    }
}

export class KenDeviceLocationModel
    extends GenericModel<IKenDeviceLocation, IKenDeviceLocationDto>
    implements IKenDeviceLocation
{
    companyCode: string;
    contactPersonName: string[];

    constructor(limits?: IKenDeviceLocationDto) {
        super(limits, KenDeviceLocationModel);

        this.companyCode = limits?.companyCode || '';
        this.contactPersonName = limits?.contactPersonName || [];
    }

    dataTransferObject(): IKenDeviceLocationDto {
        return this.getOriginalData();
    }
}
