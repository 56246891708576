import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PasswordConfirmationComponent } from '@app/pages/auth/components/password-confirmation';
import { IChangePassword } from '@app/pages/auth/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AlertBalloonComponent } from '@shared/components/alert-balloon/alert-balloon.component';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { CustomCardComponent } from '@shared/components/custom-card';
import { GlassEffectDirective } from '@shared/directives';
import { TColor } from '@shared/interfaces';
import { AppStarterService, UserStoreService } from '@shared/services';
import { translations } from '@shared/utils/translations';
import { FormHelperGroup } from '@utils/form-helper';
import { updatePassword } from 'aws-amplify/auth';

@Component({
    standalone: true,
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['../../auth.component.scss'],
    imports: [
        TranslateModule,
        CustomCardComponent,
        GlassEffectDirective,
        AuthHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        AlertBalloonComponent,
        MatProgressSpinnerModule,
        MatInputModule,
        PasswordConfirmationComponent,
        CustomButtonComponent,
    ],
})
export class ChangePasswordComponent implements OnInit {
    private appStarterService: AppStarterService = inject(AppStarterService);
    private userStoreService: UserStoreService = inject(UserStoreService);

    formGroup: FormHelperGroup<IChangePassword> = new FormHelperGroup();
    alertMsg: string = '';
    alertColor: TColor;
    passwordChanged: boolean = false;

    loading: WritableSignal<boolean> = signal(false);

    ngOnInit(): void {
        this.formGroup.build({
            email: this.userStoreService.user().email,
            old: '',
            new: '',
        });
        this.formGroup.setMandatoryFields(['old', 'new']);
    }

    goApplication(): void {
        this.appStarterService.initUser();
    }

    changePassword(): void {
        this.formGroup.form.markAllAsTouched();
        this.formGroup.validateForm();

        if (this.formGroup.invalid()) return;

        this.loading.set(true);

        updatePassword({
            oldPassword: this.formGroup.getValue('old'),
            newPassword: this.formGroup.getValue('new'),
        }).then(
            () => {
                this.loading.set(false);
                this.passwordChanged = true;
                this.alertMsg = translations.acm_password_changed_successfully;
                this.alertColor = 'success';
            },
            error => {
                this.loading.set(false);
                this.alertMsg = translations.acm_password_changed_error;
                this.alertColor = 'warn';
            },
        );
    }
}
