import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
    IStyleVariable,
    TBgColor,
    TBorderRadius,
    TColor,
    TFontSize,
    TTextColor,
} from '@shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AppStylesService {
    private cssVariables: string[] = [
        '--font-family',
        '--font-xs',
        '--font-sm',
        '--font-normal',
        '--font-md',
        '--font-lg',
        '--font-super-lg',
        '--font-xl',
        '--font-super-xl',
        '--font-super-lg-xl',
        '--border-radius',
        '--border-radius-super',
        '--border-radius-btn',
        '--border-radius-small',
        '--header-height',
        '--menu-width',
        '--page-margin',
        '--page-margin-half',
        '--item-margin',
        '--item-margin-half',
        '--icon-margin-btn',
        '--button-height',
        '--icon-size',
        '--item-height',
        '--container-padding',
        '--container-padding-small',
        '--container-padding-super-small',
        '--tooltip-bg-color',
        '--transition-time',
        '--content-min-width',
        '--carrousel-slide-width',
        '--primary-color',
        '--footer-color',
        '--error-color',
        '--delete-color',
        '--alert-color',
        '--success-color',
        '--green-color',
        '--yellow-color',
        '--excel-color',
        '--red-soft-color',
        '--alert-soft-color',
        '--green-soft-color',
        '--soft-primary-color',
        '--app-background',
        '--primary-layer-color',
        '--secondary-layer-color',
        '--tertiary-layer-color',
        '--fourth-layer-color',
        '--fifth-layer-color',
        '--text-color',
        '--text-color-soft',
        '--text-color-disabled',
        '--hover-color',
        '--strong-hover-color',
        '--divider-color',
        '--divider-stronger-color',
        '--disabled-color',
        '--button-color',
        '--color',
        '--color-text',
        '--color-text-soft',
        '--cell-hover',
        '--side-menu-color',
    ];

    private _styles: WritableSignal<IStyleVariable> = signal(Object.assign({}));

    styles: Signal<IStyleVariable> = this._styles.asReadonly();

    fnInit(): void {
        this._styles.set(
            this.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
                acc[this.convertCssVarToCamelCase(rootVar)] = this.getRootValue(rootVar);
                return acc;
            }, {}) as unknown as IStyleVariable,
        );
        // console.log(this.styles())
    }

    convertCamelToCssVar(camelCaseStr: keyof IStyleVariable) {
        // Find all uppercase letters and precede them with a hyphen and lower case them
        const kebabCaseStr = camelCaseStr.replace(/([A-Z])/g, '-$1').toLowerCase();
        return 'var(--' + kebabCaseStr + ')';
    }

    private getRootValue(rootVar: string): string {
        const rootStyles = getComputedStyle(document.documentElement);
        return rootStyles.getPropertyValue(rootVar).trim();
    }

    private convertCssVarToCamelCase(cssVar: string): string {
        // Remove the initial two dashes
        const cleanVar = cssVar.substring(2);

        // Split the string into words using hyphen as separator
        const words = cleanVar.split('-');

        // Convert to camelCase: leave the first word as is, capitalize subsequent words
        return words
            .map((word, index) => {
                return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join('');
    }

    getBorderRadius(borderRadius: TBorderRadius): string {
        if (borderRadius == 'default') return this.styles().borderRadius;
        else if (borderRadius == 'button') return this.styles().borderRadiusBtn;
        else if (borderRadius == 'small') return this.styles().borderRadiusSmall;
        else if (borderRadius == 'super') return this.styles().borderRadiusSuper;
        else return borderRadius;
    }

    getBackgroundColor(backgroundColor: TBgColor): string {
        if (backgroundColor == 'primary') return this.styles().primaryLayerColor;
        else if (backgroundColor == 'secondary') return this.styles().secondaryLayerColor;
        else if (backgroundColor == 'tertiary') return this.styles().tertiaryLayerColor;
        else if (backgroundColor == 'fourth') return this.styles().fourthLayerColor;
        else if (backgroundColor == 'fifth') return this.styles().fifthLayerColor;
        else return backgroundColor;
    }

    getColor(color: TColor): string {
        if (color == 'primary') return this.styles().primaryColor;
        else if (color == 'success') return this.styles().successColor;
        else if (color == 'green') return this.styles().greenColor;
        else if (color == 'yellow') return this.styles().yellowColor;
        else if (color == 'alert') return this.styles().alertColor;
        else if (color == 'warn') return this.styles().errorColor;
        else if (color == 'delete') return this.styles().deleteColor;
        else if (color == 'excel') return this.styles().excelColor;
        else return color;
    }

    getTextColor(textColor: TTextColor): string {
        if (textColor == 'default') return this.styles().textColor;
        else if (textColor == 'soft') return this.styles().textColorDisabled;
        else if (textColor == 'disabled') return this.styles().textColorDisabled;
        else return textColor;
    }

    getFontSize(fontSize: TFontSize): string {
        if (fontSize == 'normal') return this.styles().fontNormal;
        else if (fontSize == 'superSmall') return this.styles().fontXs;
        else if (fontSize == 'small') return this.styles().fontSm;
        else if (fontSize == 'medium') return this.styles().fontMd;
        else if (fontSize == 'large') return this.styles().fontLg;
        else if (fontSize == 'superLarge') return this.styles().fontSuperLg;
        else if (fontSize == 'xLarge') return this.styles().fontXl;
        else if (fontSize == 'xSuperLarge') return this.styles().fontSuperXl;
        else return fontSize;
    }

    getNumberFromPixels(key: keyof IStyleVariable): number {
        const pixels = this.styles()[key];
        if (typeof pixels == 'string') return Number(pixels.replace('px', ''));
        return 0;
    }

    toOpacity(key?: keyof IStyleVariable, color?: string, alpha?: number): string {
        if (key) color = this.styles()[key] as string;

        if (!color) return '';

        let r, g, b;
        if (color.includes('rgba')) {
            const split = color.split('rgba(')[1];
            const values = split.split(',');
            r = Number(values[0]);
            g = Number(values[1]);
            b = Number(values[2]);
        } else {
            color = color.toUpperCase();
            r = parseInt(color.slice(1, 3), 16);
            g = parseInt(color.slice(3, 5), 16);
            b = parseInt(color.slice(5, 7), 16);
        }

        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }
}
