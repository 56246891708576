import {
    booleanAttribute,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    numberAttribute,
    OnInit,
    Output,
    Signal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ElementFocusDirective } from '@shared/directives/element-focus.directive';
import { IStyleVariable } from '@shared/interfaces';
import { AppStylesService } from '@shared/services/app-styles.service';
import { debounceTime } from 'rxjs';

@Component({
    standalone: true,
    selector: 'search-input',
    template: `
        @if (!loading) {
            <icon name="search" [disabled]="disable" [customColor]="styles().textColorSoft" />
            <input
                [type]="type"
                [formControl]="searchControl"
                [placeholder]="'acm_search' | translate"
                (keydown)="stopPropagation ? $event.stopPropagation() : undefined"
                [element-focus]="focus" />
            @if (searchControl.value) {
                <icon
                    name="close"
                    backButtonBgPadding="2"
                    size="15"
                    (click)="searchControl.setValue('')"
                    [customColor]="styles().colorText"
                    [backButtonBgColor]="styles().textColorSoft"
                    [disabled]="disable" />
            }
        }
        @if (loading) {
            <div class="loading width-100">
                <div class="item height button super-large border-radius width-100"></div>
            </div>
        }
    `,
    imports: [
        FormsModule,
        IconComponent,
        TranslateModule,
        ReactiveFormsModule,
        ElementFocusDirective,
    ],
    styles: [
        `
            :host {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                place-content: center flex-start;
                height: var(--button-height);
                border-radius: var(--border-radius);
                background-color: var(--primary-background);
                border: 1px solid var(--divider-color);

                &:not(.loading) {
                    padding-inline: var(--container-padding-super-small);
                }

                &.border {
                    border: 1px solid var(--divider-color);
                }

                &.disable {
                    cursor: not-allowed;
                }

                &.disable input {
                    pointer-events: none;
                    opacity: 0.7;
                }
            }

            input {
                outline: none;
                border: 0;
                height: var(--button-height);
                background: transparent;
                width: 100%;
            }
        `,
    ],
})
export class SearchInputComponent implements OnInit {
    private appStylesService: AppStylesService = inject(AppStylesService);

    @Input() type: 'text' | 'number' = 'text';
    @Input() disable: boolean;
    @Input({ transform: booleanAttribute }) border: boolean;
    @Input({ transform: numberAttribute }) width: number;
    @Input({ transform: booleanAttribute }) fullWidth: boolean;
    @Input({ transform: booleanAttribute }) focus: boolean;
    @Input() widthAsString: string;
    @Input() loading: boolean | null;
    @Input({ transform: booleanAttribute }) stopPropagation: boolean;
    @Input({ transform: numberAttribute }) debounceTime: number = 180;

    @Input()
    set search(search: string) {
        if (search == this.searchControl.value) return;
        this.searchControl.setValue(search);
    }

    @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding('class.search-input') searchInput = true; // own class

    @HostBinding('class')
    get class() {
        return {
            disable: this.disable,
            border: this.border,
            loading: this.loading,
        };
    }

    styles: Signal<IStyleVariable> = this.appStylesService.styles;

    searchControl: FormControl<string | null> = new FormControl<string>('');

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(this.debounceTime))
            .subscribe((value: string | null) => {
                if (value != null) this.searchChange.emit(value);
            });
    }
}
