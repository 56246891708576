import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppStorageService } from '@shared/services/app-storage.service';
import { translations } from '@shared/utils/translations';
import { map } from 'rxjs';

const STORAGE_NAME: string = environment.localStorageSuffix + '_language';

@Injectable({
    providedIn: 'root',
})
export class AppLanguageService {
    private appStorage: AppStorageService = inject(AppStorageService);
    private translate: TranslateService = inject(TranslateService);

    languages: Signal<string[]> = signal(['en', 'de', 'fi']);
    list = computed(() => {
        return this.languages().map(key => ({
            key: key,
            name: this.translate.instant(translations.languages._base + key),
        }));
    });
    selected = computed(() => {
        return this.list().find(l => l.key === this.current());
    });

    current: Signal<string> = toSignal(
        this.translate.onLangChange.pipe(map((lang: LangChangeEvent) => lang.lang)),
        { initialValue: 'en' },
    );

    constructor() {}

    fnInit() {
        this.translate.setDefaultLang('en');

        const language: string | undefined = this.appStorage.getItem(STORAGE_NAME);

        if (language) this.translate.use(language);
    }

    set(lang: string) {
        this.translate.use(lang);
        this.appStorage.setItem(STORAGE_NAME, lang);
    }
}
