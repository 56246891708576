import { inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    IInformationDialogData,
    InformationDialogComponent,
} from '@shared/components/information-dialog';
import { IUserType } from '@shared/interfaces';
import { translations } from '@shared/utils/translations';

@Injectable()
export class UsersService {
    private dialog: MatDialog = inject(MatDialog);

    private _userTypes: WritableSignal<IUserType[]> = signal([
        { type: 'owner', translation: translations.users.type.owner },
        { type: 'tenant', translation: translations.users.type.tenant },
        { type: 'guest', translation: translations.users.type.guest },
    ]);

    userTypes: Signal<IUserType[]> = this._userTypes.asReadonly();

    callGroupsRequiredMessage(): void {
        const data: IInformationDialogData = {
            title: 'acm_call_groups_missing',
            texts: ['acm_call_groups_missing_description'],
        };
        this.dialog.open(InformationDialogComponent, {
            data,
            maxWidth: 380,
        });
    }
}
